


const Button = (props) => {
    return (
        <div>
            <button type="button" onClick={props.onClick} disabled={props.disabled} className={props.className}>{props.title}</button>
        </div>
    )
}

export default Button;