import React, { useEffect, useState } from "react";

import OutletTitle from "../Common/OutletTitle";
import ZipFileInput from "../Common/ZipFileInput";
import Button from "../Common/Button";
import PreviewImage from "../Common/PreviewImage";
import HashBoxResult from "../Common/HashBoxResult";
import FaceAddedToDB from "../Common/FaceAddedToDB";
import FileUploading from '../../assets/icons/file-uploading.svg'
import FileUploaded from '../../assets/icons/file-uploaded.svg'
import ImagesFound from '../../assets/icons/images-found.svg';
import FacesDetected from '../../assets/icons/faces-detected.svg';
import FacesChecked from '../../assets/icons/faces-checked.svg';

import { outlet_title3, outlet_title4, outlet_title5 } from "../../utils/constants";
import { useLocation } from "react-router-dom";
import Status from "../Common/Status";

const AmlAuditScreeningPage = () => {
    const [file1, setFile1] = useState(null);
    const [file1Name,setfile1Name] = useState(null);
    const [hashedImage, setHashedImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [apiCalled, setApiCalled] = useState(false);
    const [name, setName] = useState('');

    const location = useLocation(null)

    const startHashing = () => {
        console.log("called api");
        setApiCalled(true);
    }
    console.log('api called', apiCalled);

    const reset = () => {
        setFile1(null);
        setHashedImage(null);
        setLoading(false);
        setApiCalled(false);
    }

    useEffect(() => {
        reset();
    }, [location])

    const statuses = [
        {name:'File Uploading', image:FileUploading},
        {name:'File Uploaded', image:FileUploaded},
        {name:'200 images found', image:ImagesFound},
        {name:'200 faces detected', image:FacesDetected},
        {name:'200 faces checked', image:FacesChecked},
    ]

    return (
        <>
            {!apiCalled ? <>
                <OutletTitle data={outlet_title3} />
                <div className="local-row column-direction inner-body-container center">
                    <ZipFileInput file={file1} handleFile={setFile1} fileName={'setfile1Name'} fileId={'123'} fileNumber={1}/>
                    <div className="form-group local-row column-direction" style={{ paddingTop: '10px' }}>
                        <label className="form-label">Reference ID</label>
                        <input type="text" className="form-input" value={name} onChange={e => setName(e.target.value)}/>
                    </div>
                    <Button  className={file1 ? 'zipFileSubmitButton' : 'zipFileSubmitButton disabled'} onClick={startHashing} disabled={file1 ? false : true} title="SCREEN"/>
                </div>
            </> : <>
                <OutletTitle data={outlet_title5}/>
                <div className="local-row  inner-body-container">
                    <div className="local-row column-direction">
                        <ZipFileInput file={file1} handleFile={setFile1} fileName={'setfile1Name'} fileId={'123'} fileNumber={1}/>
                        <div className="form-group local-row column-direction" style={{ paddingTop: '10px' }}>
                            <label className="form-label">Reference ID</label>
                            <input type="text" className="form-input" value={name} onChange={e => setName(e.target.value)}/>
                        </div>
                    </div>
                    <div className="local-row column-direction gap justify-center status-container">
                        {statuses.map((status, index) => (
                            <Status key={'hashed'} image={status.image} title={status.name} showVerticalBorder={index != statuses.length -1 ? true : false}/>
                        ))}
                    </div>
                    <HashBoxResult showPercentage={false} success={false} text="1 False negative detected" />
                </div>
            </>}
        </>
    )
}

export default AmlAuditScreeningPage;
