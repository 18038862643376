import TickIcon from '../../assets/icons/tick-icon.svg';

const OutletTitle = (props) => {
    return (
        <div className="outlet-container">
            {props.data.map((eachTitle, index) => (
                <span key={index} className={eachTitle.isActive ? "outlet-title active" : "outlet-title" }>{eachTitle.title}
                    { eachTitle.showIcon && <span className='tick-icon'><img src={TickIcon} alt="tick-icon" /></span> }
                </span>
            ))}
            {/* <span className="outlet-title">Another tab</span> */}
        </div>
    )
}

export default OutletTitle;