import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter, BrowserRouter, Routes, Route } from "react-router-dom";

import reportWebVitals from './reportWebVitals';
import DevMode from './Components/DevMode';
import HomePage from './Components/HomePage';
import PreferencesPage from './Components/PreferencesPage';
import FaceHashMatchPage from './Components/Hashing/FaceHashMatch';
import FaceHashingPage from './Components/Hashing/FaceHashing';
import AmlAuditScreeningPage from './Components/Screenings/AMLAuditScreening';
import AdverseMediaScreeningPage from './Components/Screenings/AdverseMediaScreening';
import PEPScreeningPage from './Components/Screenings/PEPScreening';
import FraudScreeningPage from './Components/Screenings/FraudScreening';
import ErrorPage from './Components/ErrorPage';
import { urls } from './utils/constants';
import LoginPage from './Components/LoginPage';

import './index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path={urls.LOGIN} element={<LoginPage />} />
        <Route path="/" element={<App />}>
          <Route index element={<HomePage />} />
          <Route path={urls.HOME} element={<HomePage />} />
          <Route path={urls.FRAUD_SCREENING} element={<FraudScreeningPage />} />
          <Route path={urls.SANCTIONS_PEP_SCREENING} element={<PEPScreeningPage />} />
          <Route path={urls.ADVERSE_MEDIA_SCREENING} element={<AdverseMediaScreeningPage />} />
          <Route path={urls.AML_AUDIT_SCREENING} element={<AmlAuditScreeningPage />} />
          <Route path={urls.FACE_HASHING} element={<FaceHashingPage />} />
          <Route path={urls.FACE_HASH_MATCH} element={<FaceHashMatchPage />} />
          <Route path={urls.PREFERENCES} element={<PreferencesPage />} />
          <Route path={urls.DOCUMENTATION} element={<DevMode />} />
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
