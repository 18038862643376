import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ProfileIcon from '../assets/images/sundeep-user-profile.jpg';
import { snakeToCamel, checkUserLoggedIn } from "../utils/helpers";
import ProfilePage from "./ProfilePage";

const Header = (props) => {
    const [showProfile, setShowProfile] = useState(false);
    let url = useLocation().pathname.substring(1);
    const navigate = useNavigate();

    const closeProfilePopup = () => {
        setShowProfile(false);
    }

    if (url.length === 0) url = 'home';
    if (url === 'sanctions-pep-screening') url = 'sanctions-&-pep-screening';
    
    useEffect(() => {
        const res = checkUserLoggedIn();
        if (!res) navigate('/login');
    }, []);

    return (
        <>
        <div className="header">
            <div className="header-container">
                <h1>{snakeToCamel(url)}</h1>
            </div>
            <div className={ showProfile ? 'header-profile show-popup' : 'header-profile' } onClick={() => setShowProfile(!showProfile)}>
                <img src={ProfileIcon} alt="profile-icon" className="profile-icon"></img>
                <div className="profile-container">
                    <span className="profile-name">Sundeep</span>
                    <span className="profile-role">Fraud Analyst</span>
                </div>
                <div className={ showProfile ? "expand-icon expanded" : "expand-icon"}><ExpandMoreIcon /></div>
            </div>
        </div>
        {showProfile && <ProfilePage onClose={closeProfilePopup}/>}
        </>
    )
}

export default Header;