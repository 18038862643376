import { Link } from "react-router-dom";

import FraudScreeningIcon from '../assets/icons/dashboard-card-icons/fraud-screening.svg';
import SanctionPEPIcon from '../assets/icons/dashboard-card-icons/sanctions-pep.svg';
import AdverseMediaIcon from '../assets/icons/dashboard-card-icons/adverse-media-screening.svg';
import AMLAuditIcon from '../assets/icons/dashboard-card-icons/aml-audit.svg';
import FaceHashingIcon from '../assets/icons/dashboard-card-icons/face-hashing.svg';
import FaceHashMatchIcon from '../assets/icons/dashboard-card-icons/face-hash-match.svg';
import ArrowIcon from '../assets/icons/arrow-right.svg';

import CardFraudScreeningIcon from '../assets/icons/dashboard-card-headers/fraud-screening.svg';
import CardSanctionPEPIcon from '../assets/icons/dashboard-card-headers/sanctions-pep.svg';
import CardAdverseMediaIcon from '../assets/icons/dashboard-card-headers/adverse-media-screening.svg';
import CardAMLAuditIcon from '../assets/icons/dashboard-card-headers/aml-audit.svg';
import CardFaceHashingIcon from '../assets/icons/dashboard-card-headers/face-hashing.svg';
import CardFaceHashMatchIcon from '../assets/icons/dashboard-card-headers/face-hash-match.svg';

import { urls } from "../utils/constants";

const HomePage = () => {
    return <>
        <div className="local-row column-direction gap">
            <div className="local-row gap justify-center">
                <div className="dashboard-card-padding">
                    <img className="card-header-icon" src={CardFraudScreeningIcon} alt="card-fraud-screening" />
                    <div className="dashboard-card local-row column-direction gap center">
                        <img src={FraudScreeningIcon} alt='fradu Screening' className='card-icon'/>
                        <span className='card-title'>Fraud Screening </span>
                        <ol type="1">
                            <li>Real time data driven process</li>
                            <li>Uses advanced analytics to detect suspicious  patterns</li>
                            <li>Employs a multi-layered approach</li>
                        </ol>
                        <Link to={'../' +urls.FRAUD_SCREENING} className="overlay-icon">
                            <img src={ArrowIcon} alt="arrow-icon"/>
                        </Link>
                    </div>
                </div>
                <div className="dashboard-card-padding">
                    <img className="card-header-icon" src={CardSanctionPEPIcon} alt="card-sanction-screening" />
                    <div className="dashboard-card local-row column-direction gap center">
                        <img src={SanctionPEPIcon} alt='sanction pep Screening' className='card-icon'/>
                        <span className='card-title'>Sanctions & PEP Screening </span>
                        <ol type="1">
                            <li>Complies with sanctions regulations.</li>
                            <li>Real-time monitoring for matches with sanctions lists.</li>
                            <li>Mitigates legal and repetitional risks.</li>
                        </ol>
                        <Link to={'../' +urls.SANCTIONS_PEP_SCREENING} className="overlay-icon">
                            <img src={ArrowIcon} alt="arrow-icon"/>
                        </Link>
                    </div>
                </div>
                <div  className="dashboard-card-padding">
                    <img className="card-header-icon" src={CardAdverseMediaIcon} alt="card-adverse-media" />
                    <div className="dashboard-card local-row column-direction gap center">
                        <img src={AdverseMediaIcon} alt="Adverse Media Screening" className='card-icon'/>
                        <span className='card-title'>Adverse Media Screening</span>
                        <ol type="1">
                            <li>Complies with sanctions regulations.</li>
                            <li>Real-time monitoring for matches with sanctions lists.</li>
                            <li>Mitigates legal and repetitional risks.</li>
                        </ol>
                        <Link to={'../' +urls.ADVERSE_MEDIA_SCREENING} className="overlay-icon">
                            <img src={ArrowIcon} alt="arrow-icon"/>
                        </Link>
                    </div>
                </div>
            </div>
           <div className="local-row gap justify-center">
                <div className="dashboard-card-padding">
                    <img className="card-header-icon" src={CardAMLAuditIcon} alt="card-aml-audit" />
                    <div className="dashboard-card local-row column-direction gap center">
                        <img src={AMLAuditIcon} alt='AML Audit' className='card-icon'/>
                        <span className='card-title'>AML Audit</span>
                        <ol type="1">
                            <li>Real time data driven process</li>
                            <li>Uses advanced analytics to detect suspicious  patterns</li>
                            <li>Employs a multi-layered approach</li>
                        </ol>
                        <Link to={'../' +urls.AML_AUDIT_SCREENING} className="overlay-icon">
                            <img src={ArrowIcon} alt="arrow-icon"/>
                        </Link>
                    </div>
                </div>
                <div className="dashboard-card-padding">
                    <img className="card-header-icon" src={CardFaceHashingIcon} alt="card-face-hashing" />
                    <div className="dashboard-card local-row column-direction gap center">
                        <img src={FaceHashingIcon} alt='Face Hashing' className='card-icon'/>
                        <span className='card-title'>Face Hashing</span>
                        <ol type="1">
                            <li>Real time data driven process</li>
                            <li>Uses advanced analytics to detect suspicious  patterns</li>
                            <li>Employs a multi-layered approach</li>
                        </ol>
                        <Link to={'../' +urls.FACE_HASHING} className="overlay-icon">
                            <img src={ArrowIcon} alt="arrow-icon"/>
                        </Link>
                    </div>
                </div>
                <div className="dashboard-card-padding">
                    <img className="card-header-icon" src={CardFaceHashMatchIcon} alt="card-face-hash-match" />
                    <div className="dashboard-card local-row column-direction gap center">
                        <img src={FaceHashMatchIcon} alt='Face Hash Match' className='card-icon'/>
                        <span className='card-title'>Face Hash Match</span>
                        <ol type="1">
                            <li>Real time data driven process</li>
                            <li>Uses advanced analytics to detect suspicious  patterns</li>
                            <li>Employs a multi-layered approach</li>
                        </ol>
                        <Link to={'../' +urls.FACE_HASH_MATCH} className="overlay-icon">
                            <img src={ArrowIcon} alt="arrow-icon"/>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default HomePage;