import React, { useState, useEffect } from "react";

import ProtectedIcon from '../../assets/icons/protected-lock.svg';
import CrossIcon from '../../assets/icons/cross-icon.svg';
import SpinnerGif from '../../assets/images/spinner.gif'

const PreviewImage = ({
    file,
    fileName,
    fileNumber,
    hideTitle,
    removeImage=false,
    smallSize=false,
    extraSmallSize=false,
    extraExtraSmallSize=false,
    displayProtected=false,
    showHashed=false,
    showMatchedText=false,
    leftIcon = false,
    topText = '',
    loading = false,
    pics = []
}) => {
    const [showProtected, setShowProtected] = useState(true)
    const [preview, setPreview] = useState(null);

    let styleValue = '';
    styleValue += smallSize ? 'small-size' : '';
    styleValue += extraSmallSize ? 'extra-small-size' : '';
    styleValue += extraExtraSmallSize ? 'extra-extra-small-size' : '';

    useEffect(() => {
        if (!file) {
            setPreview(undefined)
            return;
        }
        let objectUrl = ''
        if(typeof file !== 'string') 
            objectUrl = URL.createObjectURL(file);
        else
            objectUrl = file
        setPreview(objectUrl);
        setShowProtected(true)
        // setTimeout(() => {
        //     setShowProtected(false)
        // }, 5000)

        return () => URL.revokeObjectURL(objectUrl)
    }, [file])

    return (
        <div className={'local-row column-direction gap ' + styleValue}>
            {!hideTitle && <span className="widget-title">{ topText ? topText : 'Upload Image ' + (fileNumber ? fileNumber : '')}</span>}
            <div className="input-container local-row justify-center">
                <div className="preview-image"
                    // onMouseEnter={() => setShowProtected(true)}
                    // onMouseLeave={() => setShowProtected(false)}
                >
                    {false ? 
                        <>
                            {pics.map((image, index) => (
                                <div className="pic" id={"pic"+(index+1)} 
                                    style={{'backgroundImage':`url(${image})`, animationDelay: ((pics.length - index - 1)*3)+'s' }}
                                 />
                            ))}
                        </>
                    : 
                    <>
                        <img src={preview} alt={fileName} />
                        {displayProtected && showProtected && <div className="overlay-text"><img className="protected" src={ProtectedIcon} alt="protected icon" />Protected</div>}
                        {removeImage && <div onClick={removeImage} className="remove-image-text"><span className="filename">{fileName}</span> <img src={CrossIcon} alt="remove icon" style={{width: '18px', height: '18px', marginTop: '3px', marginLeft: '4px' }} /></div>}
                        {showHashed && <div className="overlay-text-hashed">HASHED</div>}
                        {showMatchedText && <div className="overlay-text-matched">Matched Image</div>}
                        {loading && <div className="overlay-text-spinner"><img src={SpinnerGif} style={{borderRadius: '0px', objectFit:'contain'}} alt="left icon"/></div>}
                        {loading && <div className="overlay-text-loading">Please wait for the results to load...</div>}
                        {leftIcon && <div onClick={removeImage} className="left-icon"><img src={leftIcon} style={{borderRadius: '0px', objectFit:'contain'}} alt="left icon"/></div>}
                    </>
                    }
                </div>
            </div>
        </div>
    )
}

export default PreviewImage;