import React, { useEffect, useState } from 'react';
import SearchIcon from '../assets/icons/icon-search.svg';

import LeftArrowIcon from '../assets/icons/left-arrow-icon.svg';
import { ContriesResponse, preferencesCountriesList, preferences_tabs } from '../utils/constants';
import { convertStringToBoolean, snakeToCamel } from '../utils/helpers';

const CountriesList = ({ navigateToPreferences, activeTab = ''}) => {
    const [searchText, setSearchText] = useState('');
    const [filteredCountries, setFilteredCountries] = useState(preferencesCountriesList);
    useEffect(() => {
        if (searchText.length > 0) {
            const filteredList = []
            preferencesCountriesList.forEach(eachCountry => {
                if (eachCountry.name.toLowerCase().includes(searchText)) filteredList.push(eachCountry)
            })
            setFilteredCountries(filteredList);
        } else setFilteredCountries(preferencesCountriesList)
    }, [searchText])

    const [checkStatus, setCheckStatus] = useState(null)

    useEffect(() => {
        console.log(activeTab)
        const activeKey = activeTab === 'SANCTIONS_PEP_SCREENING' ? 'sanctions_pep' : 'aml_audit'

        setCheckStatus(convertStringToBoolean(ContriesResponse[activeKey]['country_list_settings']))

    }, []);

    return <>
    <span className="preference-breadcrumb">Preference &gt; {snakeToCamel(preferences_tabs[activeTab])} &gt; <span className='breadcrumb-active'>Country and list</span>
        <span className='breadcrumb-back-btn' onClick={navigateToPreferences}><img src={LeftArrowIcon} alt='back-btn' />Back</span>
    </span>

   <div className='table-header'>
        <span className='table-title'>Country and list settings</span>
        <div class="custom-search-container">
          <img src={SearchIcon} alt="Search Icon" class="search-icon" />
          <input type="text" placeholder="Search by country name" class="custom-search-input" value={searchText} onChange={e => setSearchText(e.target.value)} />
        </div>

    </div>
    <div className="contries-container">
        <table className="custom-table">
            <thead>
                <tr>
                    <th>S.NO</th>
                    <th>Country Name</th>
                    <th>Country</th>
                    <th>Region</th>
                    <th>National ID card</th>
                    <th>Passport</th>
                    <th>Driving License</th>
                </tr>
            </thead>
            <tbody>
                {checkStatus && filteredCountries.map((eachCountry, index) => {
                    return (
                        <tr>
                            <td>{ index + 1 }</td>
                            <td>{ eachCountry.name }</td>
                            <td><img className="contries-table-img" src={eachCountry.icon}  /></td>
                            <td>
                                <label class="custom-checkbox">
                                    <input type="checkbox" checked={checkStatus[index]['region']} />
                                    <span class="checkmark"></span>
                                </label>
                            </td>
                            <td>
                                <label class="custom-checkbox">
                                <input type="checkbox" checked={checkStatus[index]['national_id_card']} />
                                    <span class="checkmark"></span>
                                </label>
                            </td>
                            <td>
                                <label class="custom-checkbox">
                                <input type="checkbox" checked={checkStatus[index]['passport']} />
                                    <span class="checkmark"></span>
                                </label>
                            </td>
                            <td>
                                <label class="custom-checkbox">
                                <input type="checkbox" checked={checkStatus[index]['driving_license']} />
                                    <span class="checkmark"></span>
                                </label>
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    </div>
    <div className='preferences-footer'>
        <div style={{paddingLeft: '00px'}}>
            <button type="button" className="no-border-btn" >SELECT ALL</button>
            <button type="button" className="no-border-btn" >RECOMMENDED</button>
            <button type="button" className="no-border-btn" >DESELECT ALL</button>
        </div>
        <div className='right-btns'>
        <button type="button" className="save-btn" >SAVE</button>
        </div>
    </div>
    </>
}

export default CountriesList;