import UploadIcon from '../../assets/icons/upload.svg';

const FaceAddedToDB = (props) => {
    return <>
        <div className={"face-added-to-db " + props.success}>
            {props.showIcon && <img src={UploadIcon} alt="Upload" /> }
            {props.number && <span className="number">{props.number}</span>}
            <span className="title">{props.title}</span>
        </div>
    </>
}

export default FaceAddedToDB;