import FaceMatchedRed from '../../assets/icons/face-matched-red.svg';
import FaceMatchedGreen from '../../assets/icons/face-matched-green.svg'

const HashBoxResult = ({showPercentage, percentage, success, expandToMaxWidth = false, text = ''}) => {
    let styleValue = '';
    styleValue += showPercentage ? '' : 'hidePercentage';
    const expanded = expandToMaxWidth ? ' expand' : ''
    return <>
        <div className={"hash-box-result " + success + expanded}>
            <img src={success ? FaceMatchedGreen : FaceMatchedRed} />
            <div className="result-text local-row column-direction">
                <span className="result-title">{text? text : success ? 'Face Matched': 'No Match'}</span>
                {/* <span className="result-info">{success && 'Potentail Fraud'}</span> */}
            </div>
            <div className={"result-text local-row column-direction " + styleValue}>
                <span className="result-title more-font"> {percentage}% </span>
                <span className="result-info">Score</span>
            </div>
        </div>
    </>
}

export default HashBoxResult;