import React, { useState } from 'react';
import GalleryIcon from '../../assets/icons/zip-input-file-icon.svg';
import UploadedGalleryIcon from '../../assets/icons/zip-input-file-icon-uploaded.svg';

const ZipFileInput = (props) => {
  const {file} = props;
  const [isDragging, setIsDragging] = useState(false);

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    props.handleFile(file);
  };

  return (
    <div
      className={`local-row column-direction gap input-drop-area ${isDragging ? 'dragging' : ''}`}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <span className="widget-title">ATTACH A ZIP FILE</span>
      <div className="input-container">
        <label className="input-group" htmlFor={props.fileId}>
          <img src={!props.file ? GalleryIcon : UploadedGalleryIcon} alt='gallery icon' />
          {!props.file && <span className="input-group-info">Drop files here or click to upload</span>}
        </label>
        <input type="file" id={props.fileId} name={props.fileName} className='hidden' onChange={eve => props.handleFile(eve.target.files[0])} />
        <span className="input-group-title">{file? file.name : 'File'+(props.fileNumber ? props.fileNumber: '')+'*'}</span>
      </div>
    </div>
  );
}

export default ZipFileInput;
