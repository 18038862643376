import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

import RightArrowIcon from '../assets/icons/right-arrow.svg';
import CountriesList from './CountriesList';
import { snakeToCamel } from '../utils/helpers';
import { preferences_tabs, BASE_URL, IMAGE_BASE_URL, PEP_BASE_URL, PEP_IMAGE_BASE_URL, API_URLS } from '../utils/constants';

const PreferencesPage = () => {
    const [activeTab, setActiveTab] = useState('FRAUD_SCREENING');
    const [countryPage, setCountryPage] = useState(false);
    const [showCountryButton, setShowCountryButton] = useState(false);
    const [showData, setShowData] = useState({countryBtn: false, nameMatchingThreshold: false, faceMatchingThreshold: false, periodMatchingThreshold: false});
    const [faceSourceType, setFaceSourceType] = useState('checkbox');
    const [faceMatchingThresholdValue, setFaceMatchingThresholdValue] = useState({min: 0, max: 100, default: 85});
    const [nameMatchingThresholdValue, setNameMatchingThresholdValue] = useState({min: 0, max: 100, default: 78});
    const [periodMatchingThresholdValue, setPeriodMatchingThresholdValue] = useState({ min: 12, max: 48, default: 24});

    const navigateToChangePage = () => {
        setCountryPage(true);
    }

    const navigateToPreferences = () => {
        setCountryPage(false);
    }

    const reset = () => {
        setNameMatchingThresholdValue(prev => ({...prev, default: 75}));
        setFaceMatchingThresholdValue(prev => ({...prev, default: 85}));
        setPeriodMatchingThresholdValue(prev => ({...prev, default: 24}));
    };

    const location = useLocation(null);

    useEffect(() => {
        reset();
    }, [location])

    useEffect(() => {
        if (activeTab === 'FRAUD_SCREENING') {
            setShowData(prev => ({...prev, countryBtn: false, nameMatchingThreshold: false, faceMatchingThreshold: true, periodMatchingThreshold: false}));
        } else if (activeTab === 'SANCTIONS_PEP_SCREENING') {
            setShowData(prev => ({...prev, countryBtn: true, nameMatchingThreshold: true, faceMatchingThreshold: true, periodMatchingThreshold: false}));
        } else if (activeTab === 'ADVERSE_MEDIA_SCREENING') {
            setShowData(prev => ({...prev, countryBtn: false, nameMatchingThreshold: false, faceMatchingThreshold: true, periodMatchingThreshold: true}));
        } else if (activeTab === 'AML_AUDIT_SCREENING') {
            setShowData(prev => ({...prev, countryBtn: true, nameMatchingThreshold: false, faceMatchingThreshold: true, periodMatchingThreshold: true}));
        } else if (activeTab === 'FACE_HASH_MATCH') {
            setShowData(prev => ({...prev, countryBtn: false, nameMatchingThreshold: false, faceMatchingThreshold: true, periodMatchingThreshold: false}));
        }
       
        if (activeTab === 'FRAUD_SCREENING' || activeTab === 'ADVERSE_MEDIA_SCREENING') {
            setFaceSourceType('radio');
        } else setFaceSourceType('checkbox');
    }, [activeTab])

    const getUserPreferences = async () => {
        const formData = new FormData();
        const response = await axios.post( API_URLS.GET_USER_PREFERENCES, formData,{
            withCredentials: true,
             headers: {
                'Content-Type': 'multipart/form-data' 
            },
            credentials: true,
        }
        );
        console.log('preferences response', response.data);
    }
    useEffect(() => {
        getUserPreferences();
    }, [])

    if (countryPage) return <CountriesList navigateToPreferences={navigateToPreferences} activeTab={activeTab} />
    return <>
    <div className="preferences-container">
        <div className="local-row gap align-start">
            <div className="local-row column-direction preferences-title-box">
                {Object.entries(preferences_tabs).map(([key, eachTab]) => (
                    <div className={activeTab === key ? "preferences-header active": "preferences-header"} key={key} onClick={() => setActiveTab(key)}>
                        <span className="preferences-titles">{snakeToCamel(eachTab)}</span>
                        <img src={RightArrowIcon} alt='fraud screening right arrow icon' />
                    </div>
                ))}
                
            </div>
            <div className='preferences-right-box'>
                <div className='local-row column-direction gap'>
                    {showData.countryBtn && 
                        <div className='local-row gap'>
                            <span className='preference-text'>Country and list settings</span>
                            <button type="button" className='countries-button' onClick={navigateToChangePage}>CHANGE</button>
                        </div>
                    }
                    {showData.nameMatchingThreshold &&
                        <div className='local-row gap'>
                            <span className='preference-text'>Name matching threshold</span>
                            <span className='local-row range-text'>
                                <span className='range-value'>{nameMatchingThresholdValue.default}%</span>
                                {nameMatchingThresholdValue.min} <input type="range" id="name-matching-threshold" name="name-matching-threshold" value={nameMatchingThresholdValue.default} onChange={(e) => setNameMatchingThresholdValue(prev => ({...prev, default: e.target.value}))} min={nameMatchingThresholdValue.min} max={nameMatchingThresholdValue.max} /> {nameMatchingThresholdValue.max}%
                            </span>   
                        </div>
                    }
                    {showData.faceMatchingThreshold &&
                        <div className='local-row gap'>
                            <span className='preference-text'>Face matching threshold</span>
                            <span className='local-row range-text'>
                                <span className='range-value'>{faceMatchingThresholdValue.default}%</span>
                                {faceMatchingThresholdValue.min} <input type="range" id="face-matching-threshold" name="face-matching-threshold" value={faceMatchingThresholdValue.default} onChange={(e) => setFaceMatchingThresholdValue(prev => ({...prev, default: e.target.value}))} min={faceMatchingThresholdValue.min} max={faceMatchingThresholdValue.max} /> {faceMatchingThresholdValue.max}%
                            </span>
                        </div>
                    }
                    {showData.periodMatchingThreshold && 
                        <div className='local-row gap'>
                            <span className='preference-text'>Face matching threshold</span>
                            <span className='local-row range-text'>
                                <span className='range-value'>{periodMatchingThresholdValue.default} months</span>
                                {periodMatchingThresholdValue.min} <input type="range" id="period-matching-threshold" name="period-matching-threshold" value={periodMatchingThresholdValue.default} onChange={(e) => setPeriodMatchingThresholdValue(prev => ({...prev, default: e.target.value}))} min={periodMatchingThresholdValue.min} max={periodMatchingThresholdValue.max} /> {periodMatchingThresholdValue.max} months
                            </span>
                        </div>
                    }
                    <div className='local-row small-gap column-direction face-sources'>
                        <span className='preference-text-heading'>Face sources</span>
                        <label class={"custom-"+faceSourceType}>
                            <input type={faceSourceType} id="all-sources" name="face-sources" />
                            <span class={faceSourceType ==='checkbox' ? "checkmark" : 'radio-circle'}></span>
                            <label htmlFor="all-sources">All sources (rating 1-4)</label>
                        </label>
                        <label class={"custom-"+faceSourceType}>
                            <input type={faceSourceType} id="rating-three" name="face-sources"/>
                            <span class={faceSourceType ==='checkbox' ? "checkmark" : 'radio-circle'}></span>
                            <label htmlFor="rating-three">Rating 3 or less</label>
                        </label>
                        <label class={"custom-"+faceSourceType}>
                            <input type={faceSourceType} id="rating-two" name="face-sources" />
                            <span class={faceSourceType ==='checkbox' ? "checkmark" : 'radio-circle'}></span>
                            <label htmlFor="rating-two">Rating 2 or less</label>
                        </label>
                        <label class={"custom-"+faceSourceType}>
                            <input type={faceSourceType} id="rating-one" name="face-sources" />
                            <span class={faceSourceType ==='checkbox' ? "checkmark" : 'radio-circle'}></span>
                            <label htmlFor="rating-one">Rating 1</label>
                        </label>
                    </div>
                    {activeTab === 'FRAUD_SCREENING' && 
                        <label class="custom-checkbox">
                            <input type="checkbox"  id="dont-add-to-db" name="dont-add-to-db" />
                            <span class="checkmark"></span>
                            <label htmlFor="dont-add-to-db">Do not add matching hash to the fraud database.</label>
                        </label>
                    }
                </div>
            </div>
        </div>
    </div>
    <div className='preferences-footer'>
        <div className='left-btns'>
            <button type="button" className="save-btn" >RESET</button>
        </div>
        <div className='right-btns'>
            <button type="button" className="save-btn" >DEFAULT</button>
            <button type="button" className="save-btn" >SAVE</button>
        </div>
    </div>
    </>
}

export default PreferencesPage;