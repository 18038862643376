import React, { useState } from 'react';
import GalleryIcon from '../../assets/icons/input-file-icon.svg';

const FileInput = (props) => {
  const [isDragging, setIsDragging] = useState(false);

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    props.handleFile(file);
  };

  return (
    <div
      className={`local-row column-direction gap input-drop-area ${isDragging ? 'dragging' : ''}`}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <span className="widget-title">Upload Image {props.fileNumber ? props.fileNumber : ''}</span>
      <div className="input-container">
        <label className="input-group" htmlFor={props.fileId}>
          <img src={GalleryIcon} alt='gallery icon' />
          <span className="input-group-info">Drop files here or click to upload</span>
        </label>
        <input type="file" id={props.fileId} name={props.fileName} className='hidden' onChange={eve => props.handleFile(eve.target.files[0])} />
        <span className="input-group-title">Image {props.fileNumber ? props.fileNumber: ''}*</span>
      </div>
    </div>
  );
}

export default FileInput;
