import React, { useEffect, useState } from "react";
import OutletTitle from "../Common/OutletTitle";
import FileInput from "../Common/FileInput";
import HashImageTemplate from "../Common/HashImageTemplate";
import Button from "../Common/Button";
import PreviewImage from "../Common/PreviewImage";
import Status from "../Common/Status";
import { BASE_URL, IMAGE_BASE_URL, outlet_title1, outlet_title2, API_URLS } from "../../utils/constants";
import axios from 'axios';
import { useLocation } from "react-router-dom";
// import axios from '../axios';

const FaceHashingPage = () => {
    const [file1, setFile1] = useState(null);
    const [hashedImage, setHashedImage] = useState(null);
    const [binImage, setBinImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [statusArray, setStatusArray] = useState([]);
    const [apiCalled, setApiCalled] = useState(false);
    const [showProtected, setShowProtected] = useState(false);
    const [pics, setPics] = useState([]);

    const location = useLocation(null);

    const reset = () => {
        setFile1(null);
        setHashedImage(null);
        setBinImage(null);
        setLoading(false);
        setStatusArray([]);
        setApiCalled(false);
        setShowProtected(false);
    }

    useEffect(() => {
        reset();
    }, [location]);

    const file1Name = "Image 1";

    const getHashImageStatusFun = async (hashImageId) => {
        try {
            const formData1 = new FormData();
            formData1.append('hashimageid', hashImageId)
            const response = await axios.post(API_URLS.GET_HASH_IMAGES_STATUS, formData1,{
                withCredentials: true,
                headers: {
                    'Content-Type': 'multipart/form-data' 
                },
                credentials: true,
            }
            );

            const { response: { resultimage, currentstate, bin } } = response?.data;
            setHashedImage(IMAGE_BASE_URL + resultimage) 
            setBinImage(IMAGE_BASE_URL + bin)
            setStatusArray(prevStatusArray => [
                ...prevStatusArray,
                { type: 'uploaded', text: currentstate }
            ]);
            // setPics(prev => [
            //     IMAGE_BASE_URL + resultimage,
            //     ...prev
            // ])
            if(currentstate === 'IMAGE_HASHED') {
                setLoading(false)
                setTimeout(() => {
                    setShowProtected(true);
                }, 2000)
            }

            setTimeout(() => {
                if (currentstate !== 'IMAGE_HASHED') getHashImageStatusFun(hashImageId);
            }, 2000);

        } catch (e) {
            console.log(e);
            return false;
        }
    }


    const startHashing = async () => {
        try {
            setApiCalled(true);
            const formData = new FormData();
            formData.append('file', file1);
            setLoading(true);
            const response = await axios.post(API_URLS.FACE_HASHING, formData,{
                withCredentials: true,
                 headers: {
                    'Content-Type': 'multipart/form-data' 
                },
                credentials: true,
            }
            );
            setStatusArray([...statusArray,{type:'uploaded', text:'Image Uploaded' }]);
            getHashImageStatusFun(response.data.response.hashimageid)
            
          } catch (error) {
            setApiCalled(false);
            console.error('Error fetching data:', error);
          }
    }

    const handleFile = (file) => {
        setFile1(file);
    }

    const donwloadImageFun = (event) => {
        event.preventDefault();

        const anchor = document.createElement('a');
        anchor.href = binImage;
        anchor.download = 'image.bin';
    
        anchor.click();
    }

    const images = [
        'https://www.devtwins.com/images/graphics/norway3.jpeg',
        'https://www.devtwins.com/images/graphics/norway2.jpeg',
        'https://www.devtwins.com/images/graphics/norway1.jpeg'
    ]

    
    return (
        <div>
            <OutletTitle data={apiCalled ? outlet_title2 : outlet_title1}/>
            {!file1 ? (
                <>
                    <div className="inner-body-container center local-row column-direction justify-center align-center">
                        <FileInput handleFile={handleFile} fileName={file1Name} fileId={file1Name} />
                        <Button onClick={startHashing} disabled={true} className="disabled" title="Start Hashing"/>
                    </div>
                </>
                ) : (
                <div className="inner-body-container center local-row column-direction">
                    <div className="local-row  gap">
                        <div className="local-row gap column-direction">
                            <PreviewImage file={file1}  displayProtected={hashedImage && apiCalled && !loading && showProtected ? true : false} removeImage={apiCalled ? false : () => reset()} fileName={file1.name}/>
                        </div>
                        {hashedImage && <div className="local-row gap column-direction">
                            <PreviewImage topText='Hashed Image' pics={images} showHashed={apiCalled && !loading} file={hashedImage} displayProtected={false} fileName={file1.name}/>
                        </div>}
                        {apiCalled && !loading ? 
                        <div className="local-row column-direction gap justify-center status-container">
                            <Status key={'uploaded'} type={'uploaded'} title={'Image Uploaded'} showVerticalBorder={true}/>
                            {showProtected ? 
                            <>
                                <Status key={'hashed'} type={'hashed'} title={'Image Hashed  '} showVerticalBorder={true}/>
                                <Status key={'protected'} type={'protected'} title={'Image Protected'} />
                            </>
                            : <> <Status key={'hashed'} type={'hashed'} title={'Image Hashed  '}/>
                            </>
                            }
                            {loading && <Status type="emtpy" title="" /> }
                        </div>
                        : (
                            <div className="local-row column-direction gap justify-center status-container">
                                <Status key={'uploaded'} type={'uploaded'} title={'Image Uploaded'} showVerticalBorder={true}/>
                                <Status type="emtpy" title="" /> 
                            </div>
                        )}
                    </div>
                    {hashedImage ? <div className="local-row justify-center gap">
                        {/* <Button onClick={(e) => donwloadImageFun(e)}  className={"download-btn "+ (apiCalled && !loading? '' : 'disabled')} style={{'visibility': 'hidden'}} title="Download HASH"/> */}
                        <Button onClick={reset} className={loading ? 'disabled' : ''} disabled={loading} title={'BACK'}/> 
                        <button type="button"  style={{'visibility': 'hidden',marginLeft:'60px'}} > </button>
                    </div>
                    : <div className="local-row justify-center"><Button onClick={startHashing} className={apiCalled ? 'disabled hidden' : ''} disabled={apiCalled} title="Start Hashing"/></div>
                    }
                </div>
                )
            }
        </div>
    )
}

export default FaceHashingPage;

