import React, { useEffect, useState } from "react";
import OutletTitle from "../Common/OutletTitle";
import FileInput from "../Common/FileInput";
import HashImageTemplate from "../Common/HashImageTemplate";
import Button from "../Common/Button";
import PreviewImage from "../Common/PreviewImage";
import Status from "../Common/Status";
import { BASE_URL, IMAGE_BASE_URL, outlet_title1, outlet_title2, API_URLS } from "../../utils/constants";
import axios from 'axios';
import HashBoxResult from "../Common/HashBoxResult";
import { useLocation } from "react-router-dom";
import { Ring } from "react-cssfx-loading";
import BlueSpinner from '../../assets/images/blue-spinner.gif'

const FaceHashingPage = () => {
    const [file1, setFile1] = useState(null);
    const [file2, setFile2] = useState(null);
    const [hashedIcon1, setHashedIcon1] = useState(null);
    const [hashedIcon2, setHashedIcon2] = useState(null);
    const [loading, setLoading] = useState(false);
    const [apiCalled, setApiCalled] = useState(false);
    const [matchPerc, setMatchPerc] = useState(false);
    const [matchSuccess, setMatchSuccess] = useState(false);

    const location = useLocation(null)

    const reset = () => {
        setFile1(null);
        setFile2(null);
        setHashedIcon1(null);
        setHashedIcon2(null);
        setLoading(false);
        setApiCalled(false);
        setMatchPerc(false);
        setMatchSuccess(false);
    }

    useEffect(() => {
        reset();
    }, [location])

    const file1Name = "Image1";
    const file2Name = "Image2";

    const startHashing = async () => {
      try {
          setApiCalled(true);
          const formData = new FormData();
          formData.append('file1', file1);
          formData.append('file2', file2);

          setLoading(true);
          const response = await axios.post(API_URLS.FACE_HASH_MATCH, formData,{
              withCredentials: true,
               headers: {
                  'Content-Type': 'multipart/form-data' 
              },
              credentials: true,
          }
          );
          console.log(response.data)
          const {response: {match_perc, match_success, hashed_image_1, hashed_image_2, originalfilename_1, originalfilename_2}} = response.data;

          setMatchPerc(match_perc)
          setMatchSuccess(match_success)
          setHashedIcon1(IMAGE_BASE_URL + hashed_image_1)
        //   setHashedIcon2(URL.createObjectURL(file2))
          setHashedIcon2(IMAGE_BASE_URL + hashed_image_2)
        //   setHashedIcon1('https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8ZmFjZXxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60')

          setLoading(false);
                    
        } catch (error) {
          setApiCalled(false);
          setLoading(false);
          console.error('Error fetching data:', error);
        }
  }

  const resetAll = () => {
    setFile1(null);
    setFile2(null);
    setHashedIcon1(null);
    setHashedIcon2(null);
    setLoading(false);
    setApiCalled (false);
    setMatchPerc(false);
    setMatchSuccess(false);
  }
      

    return (
        <div>
            <OutletTitle data={apiCalled ? outlet_title2 : outlet_title1} />
            {file1 && file2 ? (
                <div className="inner-body-container center">
                    <PreviewImage leftIcon={hashedIcon1} file={file1} displayProtected={false} fileName={file1.name} fileNumber={1} removeImage={!apiCalled && (() => setFile1(null))} />
                    <PreviewImage leftIcon={hashedIcon2} file={file2} displayProtected={false} fileName={file2.name} fileNumber={2} removeImage={!apiCalled && (() => setFile2(null))} />
                </div>
            ) : (
                <div className="inner-body-container center">
                    {!file1? <FileInput handleFile={setFile1} fileName={file1Name} fileId={file1Name} fileNumber={1}/>
                    : (<PreviewImage file={file1} fileName={file1.name} fileNumber={1} removeImage={() => setFile1(null)} />) }
                    {!file2? (<FileInput handleFile={setFile2} fileName={file2Name} fileId={file2Name} fileNumber={2}/>)
                    : (<PreviewImage file={file2} fileName={file2.name} fileNumber={2} removeImage={() => setFile2(null)} />) }
                </div>
            )}

            {loading ? <div className="loading"><img src={BlueSpinner} style={{borderRadius: '0px', objectFit:'contain',height:'75px' }} alt="left icon"/><span>Please wait for the result to load...</span> </div> : 
            <div className="local-row justify-center" style={{margin: 'auto' }}>
                {matchPerc && <HashBoxResult expandToMaxWidth={true} showPercentage={true} percentage={matchPerc} success={matchSuccess} />}
            </div>}

            {apiCalled && !loading && <div className="local-row justify-center" style={{paddingTop: '20px'}}>Try the next image</div>}

            {!file1 || !file2 ? 
              <div className="local-row justify-center" style={{ paddingBottom: '30px'}}>
                  <Button onClick={startHashing} className='disabled' disabled={true} title="Compare" /> 
              </div>
            : apiCalled ? <div className="local-row justify-center" style={{ padding: '10px 0 30px 0'}}>
                <Button onClick={resetAll} className={loading ? 'disabled' : ''} disabled={loading} title={'BACK'}/> 
            </div>
            : <div className="local-row justify-center" style={{ paddingBottom: '30px'}}>
                <Button onClick={startHashing} className={loading ? 'disabled' : ''} disabled={loading} title={"Compare"}/> 
              </div>
               }
        </div>
    )
}

export default FaceHashingPage;