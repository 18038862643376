import React, { useEffect, useState } from "react";
import axios from 'axios';

import OutletTitle from "../Common/OutletTitle";
import FileInput from "../Common/FileInput";
import Button from "../Common/Button";
import PreviewImage from "../Common/PreviewImage";
import FaceAddedToDB from "../Common/FaceAddedToDB";

import { outlet_title3, outlet_title4, outlet_title5, IMAGE_BASE_URL, BASE_URL, PEP_IMAGE_BASE_URL, API_URLS } from "../../utils/constants";
import { useLocation } from "react-router-dom";
import FraudScreeningHashBoxResult from "../Common/FraudScreeningHashBoxResult";


import PDFDownloadIcon from '../../assets/icons/pdf-download-icon.svg';

const AdverseMediaScreeningPage = () => {
    const apiData = [
        {
            reference:"001",
            date:"10-May-2023",
            imageUrl:"https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8ZmFjZXxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60",
            matchScore:"79%",
            mediaLink:"#",
            mediaPdf:"#"
        },
        {
            reference:"001",
            date:"10-May-2023",
            imageUrl:"https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8ZmFjZXxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60",
            matchScore:"79%",
            mediaLink:"#",
            mediaPdf:"#"
        },
        {
            reference:"001",
            date:"10-May-2023",
            imageUrl:"https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8ZmFjZXxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60",
            matchScore:"79%",
            mediaLink:"#",
            mediaPdf:"#"
        },
        {
            reference:"001",
            date:"10-May-2023",
            imageUrl:"https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8ZmFjZXxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60",
            matchScore:"79%",
            mediaLink:"#",
            mediaPdf:"#"
        },
        {
            reference:"001",
            date:"10-May-2023",
            imageUrl:"https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8ZmFjZXxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60",
            matchScore:"79%",
            mediaLink:"#",
            mediaPdf:"#"
        }
    ]
    const [file1, setFile1] = useState(null);
    const [hashedImage, setHashedImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [apiCalled, setApiCalled] = useState(false);
    const location = useLocation(null)
    const [apiResponse, setApiResponse] = useState(null);

    let file1Name = "image1";
    const startHashing = async () => {
        setApiCalled(true);
        setLoading(true);
        const formData = new FormData();
        formData.append('file', file1);
        const response = await axios.post(API_URLS.ADVERSE_MEDIA_SCREENING, formData,{
            withCredentials: true,
             headers: {
                'Content-Type': 'multipart/form-data' 
            },
            credentials: true,
        }
        );
        setLoading(false);
        setApiResponse(response.data);
    }

    const reset = () => {
        setFile1(null);
        setHashedImage(null);
        setLoading(false);
        setApiCalled(false);
        setApiResponse(null);
        file1Name = "image1"
    }

    useEffect(() => {
        reset();
    }, [location])

    return (
        <>
            {!apiResponse ? <>
                <OutletTitle data={outlet_title3}/>
                <div className="local-row column-direction inner-body-container center">
                    {!file1? <FileInput handleFile={setFile1} fileName={file1Name} fileId={file1Name} />
                        : (<PreviewImage file={file1} loading={loading} removeImage={() => setFile1(null)} fileName={file1.name} />) }
                    <Button onClick={startHashing} disabled={file1 ? false : true} className={file1 ? '' :'disabled'} title="Start Screening"/>
                </div>
            </> : <>
                <OutletTitle data={outlet_title5}/>
                <div className="inner-body-container local-row gap justify-center">
                    <div className="local-row column-direction small-gap" style={{ justifyContent: 'flex-start'}}> 
                        <span className="widget-title3">Orignal Image</span>
                        <PreviewImage file={file1} fileNumber={1} extraSmallSize={true} hideTitle={true} displayProtected={true}/>
                        <PreviewImage file={file1} fileNumber={1} extraSmallSize={true} hideTitle={true} showHashed={true}/>
                    </div>
                    <div className="local-row gap column-direction">
                        <div className="local-row gap">
                            <FraudScreeningHashBoxResult success={apiResponse.review.face_matched === 'True' ? false : true}/>
                            <FaceAddedToDB title={apiResponse.review.no_of_articles ? "Articles have been found" : "No Articles found"} success={apiResponse.review.no_of_articles ? true: false} number={apiResponse.review.no_of_articles || null}/>
                        </div>
                        { apiResponse.rows && apiResponse.rows.length > 0  && 
                            <div className="adverse-table apply-scroll">
                                <table className="custom-table">
                                    <thead>
                                        <tr>
                                            <th>Reference</th>
                                            <th>Date</th>
                                            <th>Image</th>
                                            <th>Match Score</th>
                                            <th>Media Link</th>
                                            <th>Media PDF</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    { apiResponse.rows.map((e,index)=>{
                                        return (
                                        <tr key={index}>
                                            <td>{e.reference_id}</td>
                                            <td>{e.date_reported}</td>
                                            <td><img className="adverse-media-table-img" src={PEP_IMAGE_BASE_URL + e.matched_image}  /></td>
                                            <td style={{color:'Red', fontWeight: '700'}}>{e.face_match_score * 100 + '%'}</td>
                                            <td><a href={e.source_link} target="_blank" style={{fontWeight: '700'}}>Go to link</a></td>
                                            <td ><a href={PEP_IMAGE_BASE_URL + e.source_link_pdf} style={{ textDecoration: 'none',fontWeight: '700', fontSize: '13px' }}><img src={PDFDownloadIcon} alt="pdf-downloaded" />Download</a></td>
                                        </tr>
                                        )})}
                                    </tbody>
                                </table>
                            </div>
                        }
                    </div>
                </div>
                <div className="local-row justify-center column-direction" style={{padding: '0 0 20px 0'}}>
                    <span className="next-image-text">Try the next image</span>
                    <div style={{ margin: 'auto' }}>
                        <Button onClick={reset} className={loading ? 'disabled' : ''} disabled={loading} title={'BACK'}/>
                    </div>
                </div>
            </>}
        </>
    )
}

export default AdverseMediaScreeningPage;