import TickIcon from '../../../assets/icons/pep-screen-icons/tick-icon.svg';
import './pepstyles.css';

const PEPResultBox = ({percentage, numerator, denominator}) => {
    return <>
        <div className={"pep-box percentage-box" }>
            <span className='icon-bg'>
                <img src={TickIcon} />
            </span>
            <span className='pep-result-percentage'>
                {percentage > 0 ? percentage + '%' : '---'}
            </span>
            <span className='pep-result-info'>{numerator}/{denominator} Hits Cleared</span>
        </div>
    </>
}

export default PEPResultBox;