import { Link, useLocation } from "react-router-dom";

import ReguloLogo from '../assets/images/main-logo.svg';
import HomeIcon from '../assets/icons/home.svg';
import HomeActiveIcon from '../assets/icons/active/home.svg';
import FraudScreeningIcon from '../assets/icons/fraud-screening.svg';
import FraudActiveScreeningIcon from '../assets/icons/active/fraud-screening.svg';
import SanctionPEPIcon from '../assets/icons/sanctions-pep.svg';
import SanctionActivePEPIcon from '../assets/icons/active/sanctions-pep.svg';
import AdverseMediaIcon from '../assets/icons/adverse-media-screening.svg';
import AdverseActiveMediaIcon from '../assets/icons/active/adverse-media-screening.svg';
import AMLAuditIcon from '../assets/icons/aml-audit-screening.svg';
import AMLAuditActiveIcon from '../assets/icons/active/aml-audit-screening.svg';
import FaceHashingIcon from '../assets/icons/face-hasing.svg';
import FaceHashingActiveIcon from '../assets/icons/active/face-hasing.svg';
import FaceHashMatchIcon from '../assets/icons/face-hash-match.svg';
import FaceHashMatchActiveIcon from '../assets/icons/active/face-hash-match.svg';
import PreferencesIcon from '../assets/icons/preferences.svg';
import PreferencesActiveIcon from '../assets/icons/active/preferences.svg';
import DevModeIcon from '../assets/icons/dev-mode.svg';
import DevModeActiveIcon from '../assets/icons/active/dev-mode.svg';
import { urls } from "../utils/constants";

const Sidebar = () => {
    let url = useLocation().pathname.substring(1);

    if (url.length === 0) url = 'home';
    
    return (
        <div className="sidebar-container">
            <div className="sidebar-header">
                <img src={ReguloLogo} alt="regulo icon" className="header-regulo-icon"></img>
            </div>
        <div className="sidebar">
            <div className="section">
                <Link to={urls.HOME} className={'pages'+(url===urls.HOME? ' active': '')}>
                    <img src={url===urls.HOME? HomeActiveIcon: HomeIcon} alt="Home" />
                    <span className="name">Home</span>
                </Link>
            </div>
            <div className='section'>
                <span className="title">Screening</span>
                <Link to={urls.FRAUD_SCREENING} className={'pages'+(url===urls.FRAUD_SCREENING? ' active': '')}>
                    <img src={url===urls.FRAUD_SCREENING? FraudActiveScreeningIcon :FraudScreeningIcon} alt="Home" />
                    <span className="name">Fraud Screening</span>
                </Link>
                <Link to={urls.SANCTIONS_PEP_SCREENING} className={'pages sidebar-padding-imp'+(url===urls.SANCTIONS_PEP_SCREENING? ' active': '')}>
                    <img src={url===urls.SANCTIONS_PEP_SCREENING? SanctionActivePEPIcon: SanctionPEPIcon} alt="Home" />
                    <span className="name">Sanctions & PEP Screening</span>
                </Link> 
                <Link to={urls.ADVERSE_MEDIA_SCREENING} className={'pages sidebar-padding-imp'+(url===urls.ADVERSE_MEDIA_SCREENING? ' active': '')}>
                    <img src={url===urls.ADVERSE_MEDIA_SCREENING? AdverseActiveMediaIcon :AdverseMediaIcon} alt="Home" />
                    <span className="name">Adverse Media Screening</span>
                </Link> 
                <Link to={urls.AML_AUDIT_SCREENING} className={'pages'+(url===urls.AML_AUDIT_SCREENING? ' active': '')}>
                    <img src={url===urls.AML_AUDIT_SCREENING? AMLAuditActiveIcon:AMLAuditIcon} alt="Home" />
                    <span className="name">AML Audit</span>
                </Link>
            </div>
            <div className='section'>
                <span className="title">Hashing</span>
                <Link to={urls.FACE_HASHING} className={'pages'+(url===urls.FACE_HASHING? ' active': '')}>
                    <img src={url===urls.FACE_HASHING? FaceHashingActiveIcon :FaceHashingIcon} alt="Home" />
                    <span className="name">Face Hashing</span>
                </Link> 
                <Link to={urls.FACE_HASH_MATCH} className={'pages'+(url===urls.FACE_HASH_MATCH? ' active': '')}>
                    <img src={url===urls.FACE_HASH_MATCH? FaceHashMatchActiveIcon :FaceHashMatchIcon} alt="Home" />
                    <span className="name">Face Hash Match</span>
                </Link> 
                <Link to={urls.PREFERENCES} className={'pages desktop-hide'+(url===urls.PREFERENCES? ' active': '')}>
                    <img src={url===urls.PREFERENCES? PreferencesActiveIcon :PreferencesIcon} alt="Home" />
                    <span className="name">Preferences</span>
                </Link> 
                <Link to={urls.DOCUMENTATION} className={'pages desktop-hide'+(url===urls.DOCUMENTATION? ' active': '')}>
                    <img src={url===urls.DOCUMENTATION? DevModeActiveIcon:DevModeIcon} alt="Home" />
                    <span className="name">Dev Mode</span>
                </Link>
                <div className='flex-end mobile-hide'>
                <Link to={urls.PREFERENCES} className={'pages'+(url===urls.PREFERENCES? ' active': '')}>
                    <img src={url===urls.PREFERENCES? PreferencesActiveIcon :PreferencesIcon} alt="Home" />
                    <span className="name">Preferences</span>
                </Link> 
                <Link to={urls.DOCUMENTATION} className={'pages'+(url===urls.DOCUMENTATION? ' active': '')}>
                    <img src={url===urls.DOCUMENTATION? DevModeActiveIcon:DevModeIcon} alt="Home" />
                    <span className="name">Dev Mode</span>
                </Link>
                </div>
            </div>
        </div>
        </div>
    )
}

export default Sidebar;