import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';

import ReguloLogo from '../assets/images/login-logo.svg';
import { BASE_URL, IMAGE_BASE_URL, PROD, API_URLS } from "../utils/constants";
import { checkUserLoggedIn } from "../utils/helpers";

const LoginPage = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [apiResponse, setApiResponse] = useState(null);

    const loginFun = async () => {
        if (email.length === 0 || password.length === 0) setError('Invalid credentials');
        setError('');
        try {
            
            const formData = new FormData();
            formData.append('username', email);
            formData.append('password', password);
            setLoading(true);
            const response = await axios.post(API_URLS.LOGIN , formData, {
                withCredentials: true,
                 headers: {
                    'Content-Type': 'multipart/form-data'
                },
                credentials: true,
            }
            );
            console.log(response);
            setApiResponse(response.data)
            setLoading(false);
            // const localStorageData = localStorage.getItem('regulo');
            let details = { email, name: response.data.message}
            localStorage.setItem('regulo', JSON.stringify(details));
            navigate('/home');

          } catch (error) {
            setLoading(false);
            setError('Invalid credentials')
            // setError(error?.response?.data?.message);
            console.error('Error fetching data:', error);
          }
    }

    useEffect(() => {
        const res = checkUserLoggedIn();
        console.log('res', res)
        if (res) navigate('/home');
    }, []);

    return (
        <div className="login-container local-row column-direction justify-center">
            <img src={ReguloLogo} alt="regulo icon" className="login-regulo-icon"></img>
            <span className="login-title">Login To Your Account</span>
            <form className="login-form" >
                <label className="login-label">Email id</label>
                <input className="login-input" type="email" name='email' style={{fontSize: '14px'}} value={email} onChange={e => setEmail(e.target.value)} placeholder='Name@company.com' id='email' />
                <label className="login-label">Password</label>
                <input className="login-input" type="password" name='password' style={{fontSize: password.length > 0 ? '20px' :'14px'}} value={password} onChange={e => setPassword(e.target.value)} placeholder='Password' id='password' />
                {error &&  <span className='error'>{error}</span>}
                <button className="login-btn" type='button' onClick={loginFun}>LOGIN</button>
            </form>
            {/* <button className="login-request">REQUEST AN ACCOUNT</button> */}
        </div>
    )
}

export default LoginPage;