import React, { useEffect, useState } from "react";
import axios from 'axios';

import OutletTitle from "../Common/OutletTitle";
import FileInput from "../Common/FileInput";
import Button from "../Common/Button";
import PreviewImage from "../Common/PreviewImage";
import HashBoxResult from "../Common/HashBoxResult";
import FaceAddedToDB from "../Common/FaceAddedToDB";

import { outlet_title3, outlet_title5, BASE_URL, IMAGE_BASE_URL, API_URLS } from "../../utils/constants";

import { Ring } from "react-cssfx-loading";
import DownArrowIcon from '../../assets/icons/down-arrow.svg';
import PDFDownloadIcon from '../../assets/icons/pdf-download-icon.svg';

import { Link, useLocation } from "react-router-dom";
import FraudScreeningHashBoxResult from "../Common/FraudScreeningHashBoxResult";
import download from 'downloadjs';

const FraudScreeningPage = () => {
    const [file1, setFile1] = useState(null);
    const [hashedImage, setHashedImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [apiCalled, setApiCalled] = useState(false);
    const [apiResponse, setApiResponse] = useState(null); 
    const [expandTableId, setExpandTableId] = useState('');

    const location = useLocation()

    let file1Name = "image1";
    const startHashing = async () => {
        try {
            setApiCalled(true);
            const formData = new FormData();
            formData.append('file', file1);
            setLoading(true);
            const response = await axios.post(API_URLS.FRAUD_SCREENING, formData,{
                withCredentials: true,
                 headers: {
                    'Content-Type': 'multipart/form-data' 
                },
                credentials: true,
            }
            );
            console.log(response);
            setApiResponse(response.data)
            setLoading(false);
          } catch (error) {
            setApiCalled(false);
            setLoading(false);
            console.error('Error fetching data:', error);
          }
    }

    // const expandTable = (referenceId)

    useEffect(() => {
        reset()
    }, [location])

    const handleDownload = (url) => {
        console.log("url",url)
        download(url)
    }

    const reset = () => {
        setFile1(null)
        setHashedImage(null)
        setLoading(false)
        setApiCalled(false)
        setApiResponse(null)
        setExpandTableId('')
        file1Name = "image1";
    }

    return (
        <>
            {!apiCalled ? <>
                <OutletTitle data={outlet_title3}/>
                <div className="local-row column-direction inner-body-container center">
                    {!file1? <FileInput handleFile={setFile1} fileName={file1Name} fileId={file1Name} />
                        : (<PreviewImage loading={loading} file={file1} removeImage={() => setFile1(null)} fileName={file1.name}/>) }
                    <Button onClick={startHashing} disabled={file1 ? false : true} className={file1 ? '' :'disabled'} title="Start Screening"/>
                </div>
            </> : <>
                {loading ?
                <>
                    <OutletTitle data={outlet_title3}/>
                    <div className="local-row column-direction inner-body-container center">
                        <PreviewImage loading={loading} file={file1} removeImage={() => setFile1(null)} fileName={file1.name}/> 
                    </div>
                </>
                :
                <>
                <OutletTitle data={outlet_title5}/>
                <div className="inner-body-container local-row gap justify-center">
                    <div className="local-row column-direction small-gap " style={{justifyContent: 'flex-start'}}>
                        <span className="widget-title3">Orignal Image</span>
                        <PreviewImage file={file1} fileNumber={1} extraSmallSize={true} hideTitle={true} displayProtected={true}/>
                        <PreviewImage file={IMAGE_BASE_URL + apiResponse.result.hashed_image} fileNumber={1} extraSmallSize={true} hideTitle={true} showHashed={true}/>
                    </div>
                    <div className="local-row gap column-direction" style={{alignContent:'center'}}>
                        {apiResponse.result && apiResponse.result.face_matched ? <>
                            <div className="local-row small-gap" >
                                <FraudScreeningHashBoxResult  percentage={apiResponse && apiResponse.result.match_score * 100} success={false}/>
                                <FaceAddedToDB title="Face HASH has been added to the DB" showIcon={true}/>
                            </div>
                            <div className="fraud-table apply-scroll">
                            <table className="custom-table">
                                <thead>
                                    <tr>
                                        <th>Reference ID</th>
                                        <th>Date Fraud Reported</th>
                                        <th>Company Type</th>
                                        <th>Details</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {apiResponse && apiResponse.result.rows.map((entry, index) => {
                                        const companyType = entry.companyType;
                                        let showMoreData = false;
                                        let viewDetailsName = '';
                                        if (companyType === 'Regulo Research') showMoreData = true;
                                        else {
                                            if (companyType === 'Internal') {
                                                showMoreData = false;
                                                viewDetailsName = entry.details.company_internal_id;
                                            } else {
                                                showMoreData = false;
                                                viewDetailsName = '--';
                                            }
                                        }
                                        return (
                                        <>
                                        <tr key={index} className={expandTableId === index ? "expanded" : 'not-expanded'}>
                                            <td>{entry.referenceId}</td>
                                            <td>{entry.dateReported}</td>
                                            <td>{entry.companyType}</td>
                                            <td>
                                                {showMoreData && entry.details ? <>
                                                {expandTableId !== index ?
                                                <div onClick={() => setExpandTableId(index)}>
                                                    <span className="view-detials">View </span><img src={DownArrowIcon} alt="view-details" />
                                                </div>
                                                : <div onClick={() => setExpandTableId('')}>
                                                    <span className="view-detials">Hide </span><img className="expand-icon expanded" src={DownArrowIcon} alt="hide-details" />
                                                </div>}
                                                </>:  <>{viewDetailsName}</> }
                                            </td>
                                        </tr>
                                        {expandTableId === index && 
                                            <tr className="expanded-data">
                                                <td colSpan={5}>
                                                    <div className="local-row gap justify-center">
                                                        <PreviewImage showMatchedText={true} file={IMAGE_BASE_URL + entry.details.matched_image} fileNumber={1} extraExtraSmallSize={true} hideTitle={true}/>
                                                        <div className="local-row gap column-direction">
                                                            <div className="local-row gap">
                                                                
                                                                <div className="local-row column-direction">
                                                                    <span className="expanded-data-title">Source Rating</span>
                                                                    <span className="expanded-data-value">{entry.details.source_rating}</span>
                                                                </div>
                                                                <div className="local-row column-direction">
                                                                    <span className="expanded-data-title">Source Link</span>
                                                                    <span className="expanded-data-value"><a href={entry.details.source_link} target="_blank" rel="noreferrer">Link</a></span>
                                                                </div>
                                                                <div className="local-row column-direction">
                                                                    <span className="expanded-data-title">PDF File</span>
                                                                    
                                                                    <span className="expanded-data-value">
                                                                        <span style={{ 'cursor': 'pointer', 'color': '#35a2ee', fontSize: '12px', fontWeight: 600, lineHeight: '16.8px' }}  
                                                                            onClick={() => handleDownload(IMAGE_BASE_URL + entry.details.source_link_pdf)}  
                                                                        >
                                                                            <img src={PDFDownloadIcon} alt="pdf-downloaded" />
                                                                            Download 
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        }
                                        </>
                                    )})}
                                </tbody>
                            </table>
                            </div>
                        </> : <>
                            <div className="local-row gap" style={{paddingTop: '40px'}}>
                                <FraudScreeningHashBoxResult  percentage={apiResponse && apiResponse.result.match_score * 100} success={true}/>
                            </div>
                        </>}
                        
                    </div>
                    
                </div>
                <div className="local-row justify-center" style={{ paddingBottom: '30px'}}>
                    <Button onClick={reset} className={loading ? 'disabled' : ''} disabled={loading} title={'BACK'}/> 
                </div>
                </>}
            </>}
        </>
    )
}

export default FraudScreeningPage;