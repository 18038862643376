import RejectIcon from '../../../assets/icons/pep-screen-icons/reject-icon.svg';
import LikeIcon from '../../../assets/icons/pep-screen-icons/like-icon.svg';
import InfoIcon from '../../../assets/icons/pep-screen-icons/info-icon.svg';
import './pepstyles.css';

const PEPResultStatusBox = ({resultType}) => {
    return <>
        <div className={"pep-box " + resultType}>
            <span className='icon-bg'>
                <img src={resultType === 'reject' ? RejectIcon : resultType === 'info' ? InfoIcon : LikeIcon} />
            </span>
            <span className='pep-result-text'>
                {resultType === 'reject' ? 'Rejected' : resultType === 'info' ? 'Needs Review' : 'Cleared'}
            </span>
        </div>
    </>
}

export default PEPResultStatusBox;