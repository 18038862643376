import FaceMatchedRed from '../../assets/icons/fraud-screening-matched-red.svg';

const FraudScreeningHashBoxResult = ({percentage, success}) => {
    let styleValue = '';
    return <>
        <div className={"hash-box-result apply-width " + success}>
            {!success ? <>
                <img className='match-icon' src={FaceMatchedRed} alt='error icon'/>
                <div className="result-text local-row column-direction">
                    <span className="result-title">Face Matched</span>
                    <span className="result-info more-font">{'Potential Fraud'}</span>
                </div>
                <div className={"result-text local-row column-direction "}>
                {percentage && <>
                    <span className="result-title more-font"> {percentage}% </span>
                    <span className="result-info">Score</span>
                    </>
                }
                </div>
                
                </>
            : <>
               <div className="result-text fraud-no-match local-row column-direction">
                    <span className="result-title">No Match</span>
                    <span className='result-description'>No fraud detected</span>
                </div> 
            </>}
        </div>
    </>
}

export default FraudScreeningHashBoxResult;