import React, { useEffect, useState, useRef } from "react";
import axios from 'axios';

import OutletTitle from "../Common/OutletTitle";
import FileInput from "../Common/FileInput";
import Button from "../Common/Button";
import PreviewImage from "../Common/PreviewImage";
import PDFDownloadIcon from '../../assets/icons/pdf-download-icon.svg';
import CalenderIcon from '../../assets/icons/calender-icon.svg';

import BlueDownArrowIcon from '../../assets/icons/down-arrow.svg';

import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';

import { outlet_title3, outlet_title5, countriesList, PEP_IMAGE_BASE_URL, API_URLS, PROD} from "../../utils/constants";
import { useLocation } from "react-router-dom";
import PEPResultStatusBox from "../Common/PEPScreens/PEPResultStatusBox";
import PEPResultBox from "../Common/PEPScreens/PEPResultBox";
import Select from 'react-select';
import download from 'downloadjs';

import moment from 'moment';

const customerTypeOptions = [
    { value: 'Individual', label: 'Individual'},
    { value: 'Entity', label: 'Entity'}
]


const PEPScreeningPage = () => {
    const [file1, setFile1] = useState(null);
    const [apiCalled, setApiCalled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [expandTableId, setExpandTableId] = useState('');
    const [apiResponse, setApiResponse] = useState(null);
    const [enableBtn, setEnableBtn] = useState(false);
    const location = useLocation();
    const [selectedDate, setSelectedDate] = useState(null);

    const [name, setName] = useState('');
    const [customerType, setCustomerType] = useState({value: 'Individual', label: 'Individual'});
    
    const [nationality, setNationality] = useState({value: 'United States', label: 'United States'});

    let file1Name = "image1";
    const pepScreeningApiFUn = async () => {
        setApiCalled(true);
        setLoading(true);
        const formData = new FormData();

        PROD ? formData.append('image', file1) : formData.append('file', file1);
        formData.append('name', name);
        formData.append('customer_type', customerType.value);
        formData.append('dob', selectedDate);
        formData.append('nationality', nationality.value);
        const response = await axios.post( API_URLS.SANCTIONS_PEP_SCREENING  , formData,{
             headers: {
                'Content-Type': 'multipart/form-data' 
            },
        }
        );
        console.log(response.data);
        setApiResponse(response.data);
        setExpandTableId('')
        setLoading(false);
        
    }

    const reset = () => {
        setFile1(null)
        setApiCalled(false);
        setApiResponse(null);
        setName('');
        setCustomerType({value: 'Individual', label: 'Individual'})
        setSelectedDate(null);
        setNationality({value: 'United States', label: 'United States'});
        setExpandTableId('');
        file1Name = "image1"
    }

    const handleDownload = (url) => {
        console.log("url",url)
        download(url)
    }

    useEffect(() => {
        reset();
    }, [location]);

    useEffect(() => {
        if (file1 && name) setEnableBtn(true);
        else setEnableBtn(false);
    }, [file1, name]);


      const openDatePicker = () => {
        const datePickerInput = document.getElementById('datePickerInput');
        if (datePickerInput) {
            datePickerInput.click();
        }
      };

      const handleDateChange = (date) => {
        let formattedDate = date
        if(typeof(date) !== 'string'){
            const myMoment = moment(date._d, 'ddd MMM DD YYYY HH:mm:ss ZZ');
            formattedDate = myMoment.format('MM-DD-YYYY');
        }
      
        const regex = /[^0-9-]/g;
        formattedDate = formattedDate.replace(regex,'')

        formattedDate = checkAndModify(formattedDate)
        setSelectedDate(formattedDate);
      };

      const checkAndModify = (date) => {

        if(date.length === 0){
            return '';
        }
        if(date.length === 1){
            if(date[0]>'1')
                return ''
            return date
        }
        if(date.length === 2){
            if(parseInt(date) > 12)
                return date[0]
            else if(parseInt(date) === 0)
                return date[0]
            return date + '-';
        }
        if(date.length === 3){
            return date.substring(0,2)
        }
        var days = {1:31, 2:29, 3: 31, 4: 30, 5: 31, 6: 30, 7: 31, 8: 31, 9: 30, 10: 31, 11: 30, 12: 31}
        let date_split = date.split('-')
        let month = parseInt(date_split[0])
        let day = parseInt(date_split[1])
        if(date_split.length === 2){
            if(date.length === 4){
                if(parseInt(date[3])>days[month]/10)
                    return date.substring(0,3)
                return date
            }
            if(date.length === 5){
                if(day>days[month]){
                   return date.substring(0,4) 
                }
                return date+'-'
            }
        }
        if(date.length === 10){
            let year = parseInt(date_split[2])
            if(day === 29 && month === 2){
                if((year%4 === 0 && year%100 !== 0) || year%400 === 0)
                    return date
                return date.substring(0,9)
            }
        }
        if(date.length>10){
            return date.substring(0,10)
        }
        return date
      }

      const handleKeyPress = (e) => {

        const keyCode = e.keyCode || e.which;
    
        if (
          keyCode === 8 // Backspace
        ) {
            let n = selectedDate?.length || 0;
            if(n===0){
                setSelectedDate('')
            }
            else if(n>0 && selectedDate[n-1]==='-'){
                setSelectedDate(selectedDate.substring(0,n-2))
                console.log('this;', selectedDate.substring(0,n-2))
            } else {
                setSelectedDate(selectedDate.substring(0,n-1))
            }
            e.preventDefault()
        } else {
            handleDateChange(selectedDate + String.fromCharCode(e.which))
            e.preventDefault()
        }
      };
    

    
    return <>
        {!apiResponse ? <>
            <OutletTitle data={outlet_title3}/>
            <div className="local-row column-direction inner-body-container center">
                <div className="local-row gap">
                    {!file1? <FileInput handleFile={setFile1} fileName={file1Name} fileId={file1Name} />
                    : (<PreviewImage loading={loading} file={file1} removeImage={() => setFile1(null)} fileName={file1.name} />) }
                    <div className="pep-form local-row column-direction small-gap">
                        <span className="widget-title"  >ADD Details</span>
                        <div className="form-group local-row column-direction" style={{ paddingTop: '30px' }}>
                            <label className="form-label">Name <span style={{color: 'red', marginLeft: '-2px'}}>*</span></label>
                            <input type="text"  id="dummyid" className="form-input" value={name} onChange={e => setName(e.target.value)}/>
                        </div>
                        <div className="form-group local-row column-direction position-relative" >
                            <label className="form-label">Customer Type</label>
                            <Select
                                placeholder='Customer Type'
                                defaultValue={customerType}
                                onChange={selectedValue => setCustomerType(selectedValue)}
                                options={customerTypeOptions}
                                className="custom-select-dropdown"
                            />
                        </div>
                        <div className="form-group local-row column-direction">
                            <label className="form-label">DOB</label>
                            <div className="date-picker-container">
                            <Datetime
                                value={selectedDate}
                                onChange={handleDateChange}
                                dateFormat="MM-DD-YYYY"
                                // dateFormat={false}
                                inputProps={{ id: 'datePickerInput', placeholder: 'mm-dd-yyyy' }}
                                timeFormat={false}
                                closeOnSelect={ true }

                                renderInput={(props) => (
                                    <div className="input-with-icon">
                                        <input  {...props} value={selectedDate} onKeyDown={handleKeyPress} onBlur={() => {document.body.click()}} className="form-input" />
                                        <span className="calendar-icon"
                                            onClick={openDatePicker}
                                            role="button"
                                            tabIndex={0}>
                                            <img src={CalenderIcon} alt='calendar' width={18} height={20} style={{ marginRight: '5px'}}/>
                                        </span>
                                    </div>
                                )}
                            />
                            
                            </div>
                        </div>
                        <div className="form-group local-row column-direction position-relative">
                            <label className="form-label">Nationality</label>
                            <Select
                                placeholder='Nationality'
                                defaultValue={nationality}
                                onChange={selectedValue => setNationality(selectedValue)}
                                options={countriesList}
                                className="custom-select-dropdown"
                            />
                        </div>
                    </div>
                </div>
                <Button onClick={pepScreeningApiFUn} disabled={enableBtn ? false: true} className={enableBtn ? '' :'disabled'} title="Start Screening"/>
            </div>                  
        </> : <>
            <OutletTitle data={outlet_title5}/>
            <div className="inner-body-container local-row gap justify-center">
                <div className="local-row column-direction small-gap">
                    <div className=" local-row gap justify-center">
                        <div className="local-row column-direction small-gap " style={{justifyContent: 'flex-start'}}>
                            <span className="widget-title3">Orignal Image</span>
                            <PreviewImage file={file1} fileNumber={1} extraSmallSize={true} hideTitle={true} displayProtected={true}/>
                            <PreviewImage file={PEP_IMAGE_BASE_URL  + apiResponse.review.hashed_image } fileNumber={1} extraSmallSize={true} hideTitle={true} showHashed={true}/>
                        </div>
                        <div className="local-row small-gap column-direction" style={{alignContent:'center'}}>
                            <div className="local-row gap" style={{marginLeft: '20px'}}>
                                <div className="pep-form-group">
                                    <label>Name</label>
                                    <span className="pep-value">{name}</span>
                                </div>
                                <div className="pep-form-group">
                                    <label>Customer type</label>
                                    <span className="pep-value">{customerType.value}</span>
                                </div>
                                <div className="pep-form-group">
                                    <label>DOB</label>
                                    <span className="pep-value">{selectedDate ? selectedDate : 'NA'}</span>
                                </div>
                                <div className="pep-form-group">
                                    <label>Nationality</label>
                                    <span className="pep-value">{nationality.value}</span>
                                </div>
                            </div>
                            <div className="pep-result local-row column-direction">
                                <div className="local-row medium-gap " style={{ padding: '20px' }}>
                                    <PEPResultStatusBox resultType={apiResponse.review.status === 'Needs Review' ? 'info' : apiResponse.review.status === 'Rejected' ? 'reject' : 'success'} />
                                    <PEPResultBox percentage={apiResponse.review &&  (Math.round(apiResponse.review.pct_cleared * 100))} numerator={apiResponse.review && apiResponse.review.hits_cleared} denominator={apiResponse.review && apiResponse.review.total_hits} />
                                </div>
                                {apiResponse.rows && (
                                    <div className="pep-screening-table apply-scroll">
                                    <table className="pep-table custom-table">
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th >Name Matched</th>
                                                <th >Name Confidence</th>
                                                <th >Face Found</th>
                                                <th >Face Matched</th>
                                                <th >Face Match Score</th>
                                                <th >Details</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                apiResponse && apiResponse.rows && apiResponse.rows.map((eachRow, index) => {
                                                    
                                                    return  <>
                                                    <tr key={index} className={expandTableId === index ? "expanded " : 'not-expanded'}>
                                                        <td>{index + 1}</td>
                                                        <td className="left-align">{eachRow.name}</td>
                                                        <td>{Math.round(eachRow.name_match_score * 100) + '%'}</td>
                                                        <td className={eachRow.face_found ? 'success': ''}>{eachRow.face_found ? 'Found' : '-'}</td>
                                                        <td className={eachRow.face_found ? eachRow.face_matched ? 'error': 'success': ''}>{eachRow.face_found ? eachRow.face_matched ? 'Matched' : 'No Match' : '-'}</td>
                                                        <td className={eachRow.face_found ? eachRow.face_matched ? 'error': 'success': ''}>{eachRow.face_found ? eachRow.face_matched ? Math.round(eachRow.face_match_score * 100) + '%' : Math.round(eachRow.face_match_score * 100) + '%' : '-'}</td>
                                                        <td>
                                                            {eachRow.face_found ? <>
                                                            {expandTableId !== index ?
                                                            <div onClick={() => setExpandTableId(index)}>
                                                                <span className="view-detials">View </span><img src={BlueDownArrowIcon} alt="view-details" />
                                                            </div>
                                                            : <div onClick={() => setExpandTableId('')}>
                                                                <span className="view-detials">Hide </span><img className="expand-icon expanded" src={BlueDownArrowIcon} alt="hide-details" />
                                                            </div>}
                                                            </> : <>-</>}
                                                        </td>
                                                    </tr>
                                                    {expandTableId === index && 
                                                        <tr className="expanded-data">
                                                            <td colSpan={7}>
                                                                <div className="local-row gap justify-center">
                                                                    <PreviewImage 
                                                                    showMatchedText={true} 
                                                                    file={
                                                                        eachRow.details?.matched_image
                                                                        ? PEP_IMAGE_BASE_URL + eachRow.details.matched_image 
                                                                        : null
                                                                    } 
                                                                    fileNumber={1} 
                                                                    extraExtraSmallSize={true}
                                                                    hideTitle={true}
                                                                    />
                                                                    <div className="local-row gap column-direction">
                                                                        <div className="local-row gap">
                                                                            
                                                                            <div className="local-row column-direction">
                                                                                <span className="expanded-data-title">Face Match Score</span>
                                                                                <span className="expanded-data-value" style={{fontWeight: 500}}>
                                                                                    {eachRow.details?.face_match_score ? Math.round(eachRow.details.face_match_score * 100) + '%' : '-'}</span>
                                                                            </div>
                                                                            <div className="local-row column-direction">
                                                                                <span className="expanded-data-title">Identity Matching</span>
                                                                                <span className="expanded-data-value">
                                                                                    {eachRow.details?.id_match_score ? Math.round(eachRow.details.id_match_score * 100) + '%' : '-'}</span>
                                                                            </div>
                                                                            <div className="local-row column-direction">
                                                                                <span className="expanded-data-title">Last Updated</span>
                                                                                <span className="expanded-data-value">
                                                                                    {eachRow.details?.last_updated ? eachRow.details.last_updated : '-'}</span>
                                                                            </div>
                                                                            <div className="local-row column-direction">
                                                                                <span className="expanded-data-title">Source Rating</span>
                                                                                <span className="expanded-data-value">
                                                                                    {eachRow.details?.source_rating ? eachRow.details.source_rating : '-'}</span>
                                                                            </div>
                                                                            
                                                                        </div>
                                                                        <div className="local-row gap">
                                                                            <div className="local-row column-direction">
                                                                                <span className="expanded-data-title">Source Link</span>
                                                                                <span className="expanded-data-value"><a href=
                                                                                {eachRow.details?.source_link ? eachRow.details.source_link : '#'} target="_blank"  rel="noreferrer">Link</a></span>
                                                                            </div>
                                                                            <div className="local-row column-direction">
                                                                                <span className="expanded-data-title">PDF File</span>
                                                                                <span className="expanded-data-value">
                                                                                    <span style={{ 'cursor': 'pointer', 'color': '#35a2ee', fontSize: '12px', fontWeight: 600, lineHeight: '16.8px' }}  
                                                                                    onClick={() => handleDownload(eachRow.details?.source_link_pdf ? PEP_IMAGE_BASE_URL + eachRow.details.source_link_pdf : "#")}  
                                                                                    >
                                                                                        <img src={PDFDownloadIcon} alt="pdf-downloaded" />
                                                                                        Download 
                                                                                    </span>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    }
                                                    </>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="local-row justify-center column-direction" style={{padding: '0 0 20px 0'}}>
                        <span className="next-image-text">Try the next image</span>
                        <div style={{ margin: 'auto' }}>
                            <Button onClick={reset} className={loading ? 'disabled' : ''} disabled={loading} title={'BACK'}/>
                        </div>
                    </div>
                </div>
            </div>
        </> }
    </>
}

function isValidDate(str) {
    // Use a regular expression to validate the "mm-dd-yyyy" format
    const dateRegex = /^(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])-(\d{4})$/;
    return dateRegex.test(str);
  }
  
  function formatDate(str) {
    // Ensure that there are hyphens after the month and date
    const formattedStr = str.replace(/^(..)(..)/, '$1-$2-');
    return formattedStr;
  }
  
export default PEPScreeningPage;