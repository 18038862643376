import IndiaFlgIcon from '../assets/icons/countries/india.svg';
import ChinaFlgIcon from '../assets/icons/countries/china.svg';
import RussiaFlgIcon from '../assets/icons/countries/russia.svg';
import USFlgIcon from '../assets/icons/countries/us.svg';
import UKFlgIcon from '../assets/icons/countries/uk.svg';

export const urls = {
    HOME: 'home',
    FRAUD_SCREENING: 'fraud-screening',
    SANCTIONS_PEP_SCREENING: 'sanctions-pep-screening',
    ADVERSE_MEDIA_SCREENING :'adverse-media-screening',
    AML_AUDIT_SCREENING :'aml-audit-screening',
    FACE_HASHING: 'face-hashing',
    FACE_HASH_MATCH: 'face-hash-match',
    PREFERENCES: 'preferences',
    DOCUMENTATION: 'documentation',
    LOGIN: 'login',
}

export const preferences_tabs = {
    FRAUD_SCREENING: 'fraud-screening',
    SANCTIONS_PEP_SCREENING: 'sanctions-&-pep-screening',
    ADVERSE_MEDIA_SCREENING :'adverse-media-screening',
    AML_AUDIT_SCREENING :'aml-audit-screening',
    // FACE_HASHING: 'face-hashing',
    FACE_HASH_MATCH: 'face-hash-match',
}

export const PROD = process.env.REACT_APP_ENV === 'prod';

export const BASE_URL = PROD
  ? "https://api.regulo.ai/"
  : "https://dev.regulo.ai/";
export const IMAGE_BASE_URL = PROD
  ? "https://api.regulo.ai/staging_server/uploads/"
  : "https://dev.regulo.ai/staging_server/uploads/";

export const PEP_BASE_URL = PROD
  ? "https://api.regulo.ai/"
  : "https://dev.regulo.ai/";
export const PEP_IMAGE_BASE_URL = PROD
  ? "https://face-aml-target-image-collection.s3.amazonaws.com/"
  : "https://dev.regulo.ai/staging_server/server_code/static/";



export const API_URLS = {
  FACE_HASHING: BASE_URL + "hash_image/",
  GET_HASH_IMAGES_STATUS: BASE_URL + "get_hash_image_status",
  FACE_HASH_MATCH: BASE_URL + "hash_match/",
  FRAUD_SCREENING: BASE_URL + "fraud_defender",
  SANCTIONS_PEP_SCREENING: PROD
    ? PEP_BASE_URL + "api/face-aml/runfaceaml/"
    : PEP_BASE_URL + "pep_screening/",
  ADVERSE_MEDIA_SCREENING: BASE_URL + "adverse_media/",
  LOGIN: BASE_URL + "signin",
  LOGOUT: BASE_URL + "logout",
  GET_USER_PROFILE: BASE_URL + "getuserprofile",
  GET_USER_PREFERENCES: BASE_URL + "getuserpreferences",
};

export const preferencesCountriesList = [
  {
    id: 'us',
    name: 'US',
    icon: USFlgIcon
  },
  {
    id: 'uk',
    name: 'UK',
    icon: UKFlgIcon
  },
  {
    id: 'eu',
    name: 'EU',
    icon: IndiaFlgIcon
  },
  {
    id: 'un',
    name: 'UN',
    icon: IndiaFlgIcon
  },
  {
    id: 'india',
    name: 'India',
    icon: IndiaFlgIcon
  },
  {
    id: 'china',
    name: 'China',
    icon: ChinaFlgIcon
  },
  {
    id: 'russia',
    name: 'Russia',
    icon: RussiaFlgIcon
  },
]

export const outlet_title1 = [{title: 'Upload & Hashing', isActive: true, showIcon: false}]
export const outlet_title2 = [{title: 'Upload & Hashing', isActive: true, showIcon: true}]
export const outlet_title3 = [
    {title: 'Upload & Hashing', isActive: true, showIcon: false},
    {title: 'Result', isActive: false, showIcon: false}
]

export const outlet_title4 = [
    {title: 'Upload & Hashing', isActive: true, showIcon: true},
    {title: 'Result', isActive: false, showIcon: false}
]
export const outlet_title5 = [
    {title: 'Upload & Hashing', isActive: false, showIcon: true},
    {title: 'Result', isActive: true, showIcon: false}
]


export const countriesList = [
  { value: 'Afghanistan', label: 'Afghanistan' },
  { value: 'Albania', label: 'Albania' },
  { value: 'Algeria', label: 'Algeria' },
  { value: 'Andorra', label: 'Andorra' },
  { value: 'Angola', label: 'Angola' },
  { value: 'Antigua and Barbuda', label: 'Antigua and Barbuda' },
  { value: 'Argentina', label: 'Argentina' },
  { value: 'Armenia', label: 'Armenia' },
  { value: 'Australia', label: 'Australia' },
  { value: 'Austria', label: 'Austria' },
  { value: 'Azerbaijan', label: 'Azerbaijan' },
  { value: 'Bahamas', label: 'Bahamas' },
  { value: 'Bahrain', label: 'Bahrain' },
  { value: 'Bangladesh', label: 'Bangladesh' },
  { value: 'Barbados', label: 'Barbados' },
  { value: 'Belarus', label: 'Belarus' },
  { value: 'Belgium', label: 'Belgium' },
  { value: 'Belize', label: 'Belize' },
  { value: 'Benin', label: 'Benin' },
  { value: 'Bhutan', label: 'Bhutan' },
  { value: 'Bolivia', label: 'Bolivia' },
  { value: 'Bosnia and Herzegovina', label: 'Bosnia and Herzegovina' },
  { value: 'Botswana', label: 'Botswana' },
  { value: 'Brazil', label: 'Brazil' },
  { value: 'Brunei', label: 'Brunei' },
  { value: 'Bulgaria', label: 'Bulgaria' },
  { value: 'Burkina Faso', label: 'Burkina Faso' },
  { value: 'Burundi', label: 'Burundi' },
  { value: 'Cambodia', label: 'Cambodia' },
  { value: 'Cameroon', label: 'Cameroon' },
  { value: 'Canada', label: 'Canada' },
  { value: 'Cape Verde', label: 'Cape Verde' },
  {
    value: 'Central African Republic',
    label: 'Central African Republic'
  },
  { value: 'Chad', label: 'Chad' },
  { value: 'Chile', label: 'Chile' },
  { value: 'China', label: 'China' },
  { value: 'Colombia', label: 'Colombia' },
  { value: 'Comoros', label: 'Comoros' },
  { value: 'Congo (Brazzaville)', label: 'Congo (Brazzaville)' },
  { value: 'Congo (Kinshasa)', label: 'Congo (Kinshasa)' },
  { value: 'Costa Rica', label: 'Costa Rica' },
  { value: 'Croatia', label: 'Croatia' },
  { value: 'Cuba', label: 'Cuba' },
  { value: 'Cyprus', label: 'Cyprus' },
  { value: 'Czech Republic', label: 'Czech Republic' },
  { value: 'Denmark', label: 'Denmark' },
  { value: 'Djibouti', label: 'Djibouti' },
  { value: 'Dominica', label: 'Dominica' },
  { value: 'Dominican Republic', label: 'Dominican Republic' },
  { value: 'East Timor', label: 'East Timor' },
  { value: 'Ecuador', label: 'Ecuador' },
  { value: 'Egypt', label: 'Egypt' },
  { value: 'El Salvador', label: 'El Salvador' },
  { value: 'Equatorial Guinea', label: 'Equatorial Guinea' },
  { value: 'Eritrea', label: 'Eritrea' },
  { value: 'Estonia', label: 'Estonia' },
  { value: 'Ethiopia', label: 'Ethiopia' },
  { value: 'Fiji', label: 'Fiji' },
  { value: 'Finland', label: 'Finland' },
  { value: 'France', label: 'France' },
  { value: 'Gabon', label: 'Gabon' },
  { value: 'Gambia', label: 'Gambia' },
  { value: 'Georgia', label: 'Georgia' },
  { value: 'Germany', label: 'Germany' },
  { value: 'Ghana', label: 'Ghana' },
  { value: 'Greece', label: 'Greece' },
  { value: 'Grenada', label: 'Grenada' },
  { value: 'Guatemala', label: 'Guatemala' },
  { value: 'Guinea', label: 'Guinea' },
  { value: 'Guinea-Bissau', label: 'Guinea-Bissau' },
  { value: 'Guyana', label: 'Guyana' },
  { value: 'Haiti', label: 'Haiti' },
  { value: 'Honduras', label: 'Honduras' },
  { value: 'Hungary', label: 'Hungary' },
  { value: 'Iceland', label: 'Iceland' },
  { value: 'India', label: 'India' },
  { value: 'Indonesia', label: 'Indonesia' },
  { value: 'Iran', label: 'Iran' },
  { value: 'Iraq', label: 'Iraq' },
  { value: 'Ireland', label: 'Ireland' },
  { value: 'Israel', label: 'Israel' },
  { value: 'Italy', label: 'Italy' },
  { value: 'Ivory Coast', label: 'Ivory Coast' },
  { value: 'Jamaica', label: 'Jamaica' },
  { value: 'Japan', label: 'Japan' },
  { value: 'Jordan', label: 'Jordan' },
  { value: 'Kazakhstan', label: 'Kazakhstan' },
  { value: 'Kenya', label: 'Kenya' },
  { value: 'Kiribati', label: 'Kiribati' },
  { value: 'Korea, North', label: 'Korea, North' },
  { value: 'Korea, South', label: 'Korea, South' },
  { value: 'Kosovo', label: 'Kosovo' },
  { value: 'Kuwait', label: 'Kuwait' },
  { value: 'Kyrgyzstan', label: 'Kyrgyzstan' },
  { value: 'Laos', label: 'Laos' },
  { value: 'Latvia', label: 'Latvia' },
  { value: 'Lebanon', label: 'Lebanon' },
  { value: 'Lesotho', label: 'Lesotho' },
  { value: 'Liberia', label: 'Liberia' },
  { value: 'Libya', label: 'Libya' },
  { value: 'Liechtenstein', label: 'Liechtenstein' },
  { value: 'Lithuania', label: 'Lithuania' },
  { value: 'Luxembourg', label: 'Luxembourg' },
  { value: 'Macedonia', label: 'Macedonia' },
  { value: 'Madagascar', label: 'Madagascar' },
  { value: 'Malawi', label: 'Malawi' },
  { value: 'Malaysia', label: 'Malaysia' },
  { value: 'Maldives', label: 'Maldives' },
  { value: 'Mali', label: 'Mali' },
  { value: 'Malta', label: 'Malta' },
  { value: 'Marshall Islands', label: 'Marshall Islands' },
  { value: 'Mauritania', label: 'Mauritania' },
  { value: 'Mauritius', label: 'Mauritius' },
  { value: 'Mexico', label: 'Mexico' },
  { value: 'Micronesia', label: 'Micronesia' },
  { value: 'Moldova', label: 'Moldova' },
  { value: 'Monaco', label: 'Monaco' },
  { value: 'Mongolia', label: 'Mongolia' },
  { value: 'Montenegro', label: 'Montenegro' },
  { value: 'Morocco', label: 'Morocco' },
  { value: 'Mozambique', label: 'Mozambique' },
  { value: 'Myanmar (Burma)', label: 'Myanmar (Burma)' },
  { value: 'Namibia', label: 'Namibia' },
  { value: 'Nauru', label: 'Nauru' },
  { value: 'Nepal', label: 'Nepal' },
  { value: 'Netherlands', label: 'Netherlands' },
  { value: 'New Zealand', label: 'New Zealand' },
  { value: 'Nicaragua', label: 'Nicaragua' },
  { value: 'Niger', label: 'Niger' },
  { value: 'Nigeria', label: 'Nigeria' },
  { value: 'Norway', label: 'Norway' },
  { value: 'Oman', label: 'Oman' },
  { value: 'Pakistan', label: 'Pakistan' },
  { value: 'Palau', label: 'Palau' },
  { value: 'Palestine', label: 'Palestine' },
  { value: 'Panama', label: 'Panama' },
  { value: 'Papua New Guinea', label: 'Papua New Guinea' },
  { value: 'Paraguay', label: 'Paraguay' },
  { value: 'Peru', label: 'Peru' },
  { value: 'Philippines', label: 'Philippines' },
  { value: 'Poland', label: 'Poland' },
  { value: 'Portugal', label: 'Portugal' },
  { value: 'Qatar', label: 'Qatar' },
  { value: 'Romania', label: 'Romania' },
  { value: 'Russia', label: 'Russia' },
  { value: 'Rwanda', label: 'Rwanda' },
  { value: 'Saint Kitts and Nevis', label: 'Saint Kitts and Nevis' },
  { value: 'Saint Lucia', label: 'Saint Lucia' },
  {
    value: 'Saint Vincent and the Grenadines',
    label: 'Saint Vincent and the Grenadines'
  },
  { value: 'Samoa', label: 'Samoa' },
  { value: 'San Marino', label: 'San Marino' },
  { value: 'Sao Tome and Principe', label: 'Sao Tome and Principe' },
  { value: 'Saudi Arabia', label: 'Saudi Arabia' },
  { value: 'Senegal', label: 'Senegal' },
  { value: 'Serbia', label: 'Serbia' },
  { value: 'Seychelles', label: 'Seychelles' },
  { value: 'Sierra Leone', label: 'Sierra Leone' },
  { value: 'Singapore', label: 'Singapore' },
  { value: 'Slovakia', label: 'Slovakia' },
  { value: 'Slovenia', label: 'Slovenia' },
  { value: 'Solomon Islands', label: 'Solomon Islands' },
  { value: 'Somalia', label: 'Somalia' },
  { value: 'South Africa', label: 'South Africa' },
  { value: 'South Sudan', label: 'South Sudan' },
  { value: 'Spain', label: 'Spain' },
  { value: 'Sri Lanka', label: 'Sri Lanka' },
  { value: 'Sudan', label: 'Sudan' },
  { value: 'Suriname', label: 'Suriname' },
  { value: 'Swaziland', label: 'Swaziland' },
  { value: 'Sweden', label: 'Sweden' },
  { value: 'Switzerland', label: 'Switzerland' },
  { value: 'Syria', label: 'Syria' },
  { value: 'Taiwan', label: 'Taiwan' },
  { value: 'Tajikistan', label: 'Tajikistan' },
  { value: 'Tanzania', label: 'Tanzania' },
  { value: 'Thailand', label: 'Thailand' },
  { value: 'Togo', label: 'Togo' },
  { value: 'Tonga', label: 'Tonga' },
  { value: 'Trinidad and Tobago', label: 'Trinidad and Tobago' },
  { value: 'Tunisia', label: 'Tunisia' },
  { value: 'Turkey', label: 'Turkey' },
  { value: 'Turkmenistan', label: 'Turkmenistan' },
  { value: 'Tuvalu', label: 'Tuvalu' },
  { value: 'Uganda', label: 'Uganda' },
  { value: 'Ukraine', label: 'Ukraine' },
  { value: 'United Arab Emirates', label: 'United Arab Emirates' },
  { value: 'United Kingdom', label: 'United Kingdom' },
  {
    value: 'United States',
    label: 'United States'
  },
  { value: 'Uruguay', label: 'Uruguay' },
  { value: 'Uzbekistan', label: 'Uzbekistan' },
  { value: 'Vanuatu', label: 'Vanuatu' },
  { value: 'Vatican City', label: 'Vatican City' },
  { value: 'Venezuela', label: 'Venezuela' },
  { value: 'Vietnam', label: 'Vietnam' },
  { value: 'Yemen', label: 'Yemen' },
  { value: 'Zambia', label: 'Zambia' },
  { value: 'Zimbabwe', label: 'Zimbabwe' }
  ]
   


  export const ContriesResponse = {
    "fraud_screening": {
      "face_matching_threshold": "70",
      "_comment1": "10-100%",
      "face_source": "3",
      "_comment2": "1-5,x=>xandabove",
      "add_hash_to_db": "false",
      "_comment3": "true/false"
    },
    "sanctions_pep": {
      "country_list_settings": [
        {
          "country": "US",
          "region": "true",
          "national_id_card": "true",
          "passport": "true",
          "driving_license": "true"
        },
        {
          "country": "UK",
          "region": "true",
          "national_id_card": "true",
          "passport": "true",
          "driving_license": "true"
        },
        {
          "country": "EU",
          "region": "true",
          "national_id_card": "true",
          "passport": "true",
          "driving_license": "true"
        },
        {
          "country": "UN",
          "region": "true",
          "national_id_card": "false",
          "passport": "true",
          "driving_license": "true"
        },
        {
          "country": "INDIA",
          "region": "true",
          "national_id_card": "true",
          "passport": "true",
          "driving_license": "true"
        },
        {
          "country": "CHINA",
          "region": "true",
          "national_id_card": "true",
          "passport": "true",
          "driving_license": "true"
        },
        {
          "country": "RUSSIA",
          "region": "true",
          "national_id_card": "true",
          "passport": "true",
          "driving_license": "true"
        }
      ],
      "face_matching_threshold": "70",
      "_comment1": "0-100%",
      "name_matching_threshold": "70",
      "_comment2": "0-100%",
      "face_source": "3",
      "_comment3": "1-5,x=>xandabove"
    },
    "aml_audit": {
      "_comment0": "sameassanctions_pep",
      "country_list_settings": [
        {
          "country": "US",
          "region": "true",
          "national_id_card": "true",
          "passport": "true",
          "driving_license": "true"
        },
        {
          "country": "UK",
          "region": "true",
          "national_id_card": "true",
          "passport": "true",
          "driving_license": "true"
        },
        {
          "country": "EU",
          "region": "true",
          "national_id_card": "true",
          "passport": "true",
          "driving_license": "true"
        },
        {
          "country": "UN",
          "region": "true",
          "national_id_card": "true",
          "passport": "true",
          "driving_license": "true"
        },
        {
          "country": "INDIA",
          "region": "true",
          "national_id_card": "false",
          "passport": "true",
          "driving_license": "false"
        },
        {
          "country": "CHINA",
          "region": "true",
          "national_id_card": "true",
          "passport": "true",
          "driving_license": "true"
        },
        {
          "country": "RUSSIA",
          "region": "true",
          "national_id_card": "true",
          "passport": "true",
          "driving_license": "true"
        }
      ],
      "face_matching_threshold": "70",
      "_comment1": "0-100%",
      "name_matching_threshold": "70",
      "_comment2": "0-100%",
      "face_source": "3",
      "_comment3": "1-5,x=>xandabove"
    },
    "face_match": {
      "face_matching_threshold": "70",
      "_comment1": "0-100%"
    },
    "adverse_media": {
      "face_source": "3",
      "_comment1": "1-5,x=>xandabove",
      "face_matching_threshold": "70",
      "_comment2": "0-100%",
      "period_matching_threshold": "24",
      "_comment3": "12-48months"
    }
  }