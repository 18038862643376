import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { BASE_URL, IMAGE_BASE_URL, API_URLS } from "../utils/constants";

import axios from 'axios';

import GalleryIcon from '../assets/icons/gallery.svg';
import LogoutIcon from '../assets/icons/logout.svg';
import CrossIcon from '../assets/icons/cross-icon.svg';

const ProfilePage = ({ onClose }) => {
    const navigate = useNavigate();
    const [isEdit, setIsEdit] = useState(false);
    const [userName, setUserName] = useState('Sundeep Bhimireddy');
    const [userEmail, setUserEmail] = useState('sundeep@regulo.ai');
    const [companyName, setCompanyName] = useState('Regulo');
    const [loading, setLoading] = useState(false);
    const popupRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [file, setFile] = useState(null);

    const saveChanges = () => {
        setIsEdit(false);
    }

    const logoutFun = async () => {
        try {
            const formData = new FormData();
            setLoading(true);
            const response = await axios.post( API_URLS.LOGOUT, formData,{
                withCredentials: true,
                 headers: {
                    'Content-Type': 'multipart/form-data' 
                },
                credentials: true,
            }
            );
            console.log(response);
            localStorage.removeItem('regulo');
            navigate('/login');

          } catch (error) {
            setLoading(false);
            alert('logout api failed');
            console.error('Error fetching data:', error);
          }
    }

    const getUserProfileData = async () => {
      try {
        const formData = new FormData();
        setLoading(true);
        const response = await axios.post(API_URLS.GET_USER_PROFILE, formData,{
            withCredentials: true,
             headers: {
                'Content-Type': 'multipart/form-data' 
            },
            credentials: true,
        }
        );
        console.log(response);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error('Error fetching data:', error);
      }
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
          if (popupRef.current && !popupRef.current.contains(event.target) && !event.target.closest('.header-profile')) {
            onClose();
          }
        };

        document.addEventListener('mousedown', handleClickOutside);
    
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [onClose]);

      const handleDragOver = (e) => {
        if(!isEdit)
          return;
        e.preventDefault();
        setIsDragging(true);
      };
    
      const handleDragLeave = (e) => {
        if(!isEdit)
          return;
        e.preventDefault();
        setIsDragging(false);
      };
    
      const handleDrop = (e) => {
        if(!isEdit)
          return;
        e.preventDefault();
        setIsDragging(false);
        const file = e.dataTransfer.files[0];
        setFile(file);
      };

      const removeImage = (e) => {
        e.preventDefault();
        setFile(null);
      }

    useEffect(() => {
      getUserProfileData();
    }, [])

    return <>
        <div className="profile-popup-container" ref={popupRef}>
            <div className="local-row column-direction apply-padding">
                <span className="title">Personal Settings</span>
                <label className="label">User Name</label>
                { isEdit ? <input type="text" className='profile-input' name='user-name' id='user-name' style={{marginBottom: '14px'}} value={userName} onChange={e => setUserName(e.target.value)} />
                : <span className="text-css" style={{marginBottom: '20px'}}>{userName}</span>
                }

                <label className="label">Email ID</label>
                <span className="text-css">{userEmail}</span>
            </div>
            <div className="separator"></div>
            <div className="local-row column-direction apply-padding">
                <span className="title">General Settings</span>
                <label className="label">Company Logo</label>
                <label htmlFor={file ? '' : "profile-image"} >
                  <img src={file ? URL.createObjectURL(file) : GalleryIcon} className={`input-drop-area ${isDragging ? 'dragging' : ''} company-logo`}  style={{marginBottom: '10px'}} alt='company-logo' 
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                  />
                  {isEdit && file && <div onClick={(e) => removeImage(e)} className="remove-profile-image"><img src={CrossIcon} alt="remove icon" style={{width: '18px', height: '18px' }} /></div>}
                </label>
                {isEdit && !file && <input id="profile-image" type="file" className='hidden' onChange={eve => setFile(eve.target.files[0])} />}
                <label className="label">Company name</label>
                { isEdit ? <input type="email" className='profile-input' name='user-name' id='user-name' value={companyName} onChange={e => setCompanyName(e.target.value)} />
                : <span className="text-css">{companyName}</span>
                }
            </div>
            <div className='local-row justify-center'>
            { isEdit ? <button type='button' className='button profile-button' onClick={saveChanges}>SAVE CHANGES</button>
            : <button type='button' className='button profile-button' onClick={() => setIsEdit(true)}>EDIT</button>
            }
            </div>
            <div className="separator"></div>
            <div className="logout-button">
                <button type='button' className='button profile-button logout-btn urban-font-family' onClick={logoutFun}>Logout 
                    <img src={LogoutIcon} alt="logout button" />
                </button>
            </div>
        </div>
    </>
}

export default ProfilePage;