import React from "react";
import { useLocation, Outlet } from 'react-router-dom';

import Header from './Components/Header';
import Sidebar from './Components/Sidebar';
import 'bootstrap/dist/css/bootstrap.min.css';


import "@fontsource/urbanist";
import "@fontsource/outfit";


import './App.css';
import './assets/styles/icons.css';

function App() {
  const url = useLocation().pathname.substring(1);

  return (
    <div className="App">
      <Header />
      <div className="body-container">
        <Sidebar />
        <div className={url === '' || url === 'home' ? "dashboard" : url === 'preferences' ? "body preferences custom-scroll" : "body custom-scroll"}>
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default App;
