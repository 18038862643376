import ImageUploaded from '../../assets/icons/image-uploaded.svg';
import ImageProtected from '../../assets/icons/image-protected.svg';
import ImageHashed from '../../assets/icons/image-hashed.svg';
import { snakeToCamelCaseWithUnderscore } from '../../utils/helpers';

const Status = (props) => {
    const { type=false, title, showVerticalBorder=false, image=false } = props;
    return (
        <div className="local-row small-gap align-items-center">
            <div className='status-icon'>
                {
                    image ? <img src={image} alt={title} /> : 
                    type === 'uploaded' ? <img src={ImageUploaded} alt="uploaded icon" /> :
                    type === 'protected' ? <img src={ImageProtected} alt="protected icon" /> :
                    type === 'hashed' ? <img src={ImageHashed} alt="hashed icon" /> : <></>
                }
                {showVerticalBorder && <span className='status-vertical-border'></span>}
            </div>
            <span className={title.length ? 'status-title' : 'status-title no-title'}>{(title)}</span>
        </div>
    )
}

export default Status;