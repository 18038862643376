
export function snakeToCamel(input) {
    if (typeof input !== 'string') {
        input = String(input);
    }    
    const words = input?.split('-');
    const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
    const output = capitalizedWords.join(' ');
    return output;
}

export function snakeToCamelCaseWithUnderscore(input) {
    if (typeof input !== 'string') {
        input = String(input);
    }    
    const words = input?.split('_');
    const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
    const output = capitalizedWords.join(' ');
    return output;
}


export function checkUserLoggedIn() {
    const localStorageData = JSON.parse(localStorage.getItem('regulo'));
    if (!localStorageData) return false;
    if (localStorageData && localStorageData.email) return true;
    return false;
}

export function convertStringToBoolean(obj) {
    for (let key in obj) {
      if (typeof obj[key] === 'object') {
        convertStringToBoolean(obj[key]);
      } else {
        if (obj[key] === 'true') {
          obj[key] = true;
        } else if (obj[key] === 'false') {
          obj[key] = false;
        }
      }
    }
    return obj;
  }
  